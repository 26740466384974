import ContentFilter from '../utility/ContentFilter'
import DataFormatterImpl from '../utility/DataFormatterImpl'

import {
    archivedCardExpander,
    boardIdExpander,
    boardNameExpander,
    commentsLengthExpander,
    customFieldCSVExpander,
    dateCardCreation,
    listNameExpander,
    votesExpander
} from '../utility/Expanders'
import {checklistDataAppender} from '../utility/Appenders'
import {parse} from 'json2csv'
import {
    attachmentsTransformer,
    dateFormatTransformer,
    labelsTransformer,
    membersTransformer
} from 'trello-shared-resources/dist/modules/utility/Transformers'
import FileSaver from 'filesaver-js'
import {getChecklistFieldNames, getCustomFieldNames} from '../utility/Calculations'
import {csvDescriptionTransformer, urlTransformer} from '../utility/Transformers'
import {getCardsToExportForEvaluation, isNewEvaluationLicensed} from '../utility/Utils'

class CSVExport {

    HEADERS = ['id',
        'idShort',
        'name',
        'url',
        'desc',
        'labels',
        'members',
        'creationDate',
        'due',
        'attachments',
        'votes',
        'comments',
        'dateLastActivity',
        'address',
        'idList',
        'listName',
        'idBoard',
        'boardName'
    ]

    constructor(fieldsSelected) {
        // --== File Format ==--
        this.FILE_TYPE = 'text/csv;charset=utf-8;'

        const fieldNames = fieldsSelected.map(field => field.value)

        this.contentFilter = new ContentFilter()
        let transformationalFields = {
            'members': membersTransformer,
            'labels': labelsTransformer,
            'due': dateFormatTransformer,
            'url': urlTransformer,
            'desc': csvDescriptionTransformer,
            'attachments': attachmentsTransformer
        }
        transformationalFields = Object.keys(transformationalFields)
            .filter(key => fieldNames.includes(key))
            .reduce((obj, key) => {
                obj[key] = transformationalFields[key];
                return obj;
            }, {})

        let expansionFields = {
            'customFieldItems': customFieldCSVExpander,
            'listName': listNameExpander,
            'votes': votesExpander,
            'idBoard': boardIdExpander,
            'boardName': boardNameExpander,
            'comments': commentsLengthExpander,
            'archivedCard': archivedCardExpander,
            'creationDate': dateCardCreation
        }

        expansionFields = Object.keys(expansionFields)
            .filter(key => fieldNames.includes(key))
            .reduce((obj, key) => {
                obj[key] = expansionFields[key];
                return obj;
            }, {})

        this.dataFormatter = new DataFormatterImpl(
            this.HEADERS, // <- Fields that we want to use from the cards
            // Fields that need transformational formatting (i.e. Objects / complex types)
            transformationalFields,
            // Fields that need expansion (i.e. compositing data from other sources)
            expansionFields,
            [
                checklistDataAppender
            ]
        )
    }

    /**
     * Export the cards and all their field values (including custom) from the lists identified by the given list names.
     * @param {*} listIds The ids of the lists we want to export.
     * @param {*} trelloData An object containing all of the required data from Trello.
     * @param {Array<{label: string, value: string}>} fieldsSelected A list of objects {label, value} with the selected fields to export
     * @param licenseDetails object to get current memberId and trello app key
     */
    async export(listIds, trelloData, fieldsSelected, licenseDetails) {
        // 1. Filter the lists to return ONLY the ones specified
        const filteredLists = this.contentFilter.filterLists(listIds, trelloData.lists)

        // 2. Compile a list of all of the cards in those lists
        const filteredCardData = await this.dataFormatter.getCardDataFromLists({
            'list': filteredLists,
            ...trelloData
        }, fieldsSelected.map(field => field.value), licenseDetails)

        // 3. Generate CSV output using the Papa.unparse(data[, config])
        const csvOutput = this._createCsvFromCards(filteredCardData, fieldsSelected, licenseDetails)

        // 4. Save to file using SaveFile module
        const currentDate = (new Date()).toISOString().substring(0, 10).replace('-', '').replace('-', '')
        const fileName = trelloData.board.name + ' ' + currentDate + '.csv'
        FileSaver.saveAs(new Blob([csvOutput], {type: 'text/csv'}), fileName)
        this.dataFormatter.fieldsToOutput = this.HEADERS
    }

    /**
     * Converts the given array of JS Objects into a CSV String that can be written to a file.
     * @param {*} listOfCardData A list of card data that we want to convert to CSV format.
     * @param {Array<{label: string, value: string}>} fieldsSelected A list of objects {label, value} with the selected fields to export
     * @param licenseDetails object to get current memberId and trello app key
     * @return a list of objects {label, value} with the headers to export
     */
    _createCsvFromCards(listOfCardData, fieldsSelected, licenseDetails) {
        const opts = {eol: '\r\n'}
        const customFieldNames = getCustomFieldNames(listOfCardData)
        const checklistFieldNames = getChecklistFieldNames(listOfCardData)
        let fieldNames = fieldsSelected.map(field => field.value)
        let headers = [
            {label: 'Card Name', value: 'name'},
            {label: 'Card ID', value: 'id'},
            {label: 'Short Card ID', value: 'idShort'},
            {label: 'Card URL', value: 'url'},
            {label: 'Board Name', value: 'boardName'},
            {label: 'Board ID', value: 'idBoard'},
            {label: 'List Name', value: 'listName'},
            {label: 'List ID', value: 'idList'},
            {label: 'Members', value: 'members'},
            {label: 'Labels', value: 'labels'},
            {label: 'Card Created Date', value: 'creationDate'},
            {label: 'Due Date', value: 'due'},
            {label: 'Description', value: 'desc'}
        ].filter(header => fieldNames.includes(header.value))
        if (fieldNames.includes('customFieldItems')) headers = headers.concat(customFieldNames.map(customFieldName => ({
            label: customFieldName,
            value: customFieldName
        })))
        if (fieldNames.includes('checklists')) headers = headers.concat(checklistFieldNames.map(customFieldName => ({
            label: customFieldName,
            value: customFieldName
        })))
        headers = headers.concat([
            {label: 'Checklist Items Total', value: 'checklistItemTotal'},
            {label: 'Checklist Items Completed', value: 'checklistItemCompleted'},
            {label: 'Attachments', value: 'attachments'},
            {label: 'Location', value: 'address'},
            {label: 'Votes', value: 'votes'},
            {label: 'Number of Comments', value: 'comments'},
            {label: 'Last Activity Date', value: 'dateLastActivity'},
            {label: 'Archived card', value: 'archivedCard'}

        ].filter(header => fieldNames.includes(header.value)))
        opts.fields = headers

        let cardsData = listOfCardData
        if (isNewEvaluationLicensed(licenseDetails)) {
            const archivedCards = listOfCardData.filter(card => card.archivedCard !== 'N/A')
            const nonArchivedCards = listOfCardData.filter(card => card.archivedCard === 'N/A')
            const maxCardsToExport = parseInt(process.env.REACT_APP_EVAL_EXPORT_CSV_CARDS || '5')
            const maxNonArchivedCardsToExport = parseInt(process.env.REACT_APP_EVAL_CSV_MAX_NON_ARCHIVED_CARDS || '3')
            const maxArchivedCardsToExport = parseInt(process.env.REACT_APP_EVAL_CSV_MAX_ARCHIVED_CARDS || '2')
            cardsData = getCardsToExportForEvaluation(nonArchivedCards, archivedCards, maxCardsToExport, maxNonArchivedCardsToExport, maxArchivedCardsToExport)
            const firstHeader = headers[0].value
            cardsData = [...cardsData, ...[{[firstHeader]: ''},
                {[firstHeader]: process.env.REACT_APP_EVAL_TEXT_1},
                {[firstHeader]: process.env.REACT_APP_EVAL_TEXT_2},
                {[firstHeader]: process.env.REACT_APP_EVAL_LIMIT_DOCS_LINK}]]
        }
        return parse(cardsData, opts)
    }
}

export default CSVExport