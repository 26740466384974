import ContentFilter from '../utility/ContentFilter'
import DataFormatterImpl from '../utility/DataFormatterImpl'
import {pdfChecklistDataAppender} from '../utility/Appenders'
import {pdf} from '@react-pdf/renderer'
import PDFExportDocument from '../../components/export/PDF/PDFExportDocument'
import React from 'react'
import FileSaver from 'filesaver-js'
import {membersTransformer} from 'trello-shared-resources/dist/modules/utility/Transformers'
import {
    archivedCardPDFExpander,
    boardNameExpander,
    cardProgressExpander,
    commentsExpander,
    customFieldPDFExpander, dateCardPDFCreation,
    listNameExpander
} from '../utility/Expanders'
import {dueTransformer, labelsWithColorTransformer} from '../utility/Transformers'
import {getCardsToExportForEvaluation, isNewEvaluationLicensed} from '../utility/Utils'

class PDFExport {

    constructor() {
        this.contentFilter = new ContentFilter()
        this.dataFormatter = new DataFormatterImpl(
            ['idShort', 'name', 'desc', 'idList', 'members', 'labels', 'due', 'dueComplete', 'url', 'attachments', 'closed'], // <- Fields that we want to use from the cards
            {
                'members': membersTransformer,
                'labels': labelsWithColorTransformer,
                'due': dueTransformer,
            },
            {
                'listName': listNameExpander,
                'boardName': boardNameExpander,
                'activity': commentsExpander,
                'badges': cardProgressExpander,
                'customFieldItems': customFieldPDFExpander,
                'archivedCard': archivedCardPDFExpander,
                'creationDate': dateCardPDFCreation
            },
            [
                pdfChecklistDataAppender
            ]
        );
    }

    /**
     * Export the cards and all their field values (including custom) from the lists identified by the given list names.
     * @param {*} listIds The ids of the lists we want to export data for.
     * @param {*} trelloData An object containing all of the required data from Trello.
     * @param {Array<{label: string, value: string}>} fieldsSelected A list of objects with the selected fields to export
     * @param licenseDetails object to get current memberId and trello app key
     */
    async export(listIds, trelloData, fieldsSelected, licenseDetails) {
        const fields = fieldsSelected.map(field => field.value)

        // 1. Filter the lists to return ONLY the ones specified
        const filteredLists = this.contentFilter.filterLists(listIds, trelloData.lists)

        // 2. Compile a list of all of the cards in those lists
        const filteredCardData = await this.dataFormatter.getCardDataFromLists({
            'list': filteredLists,
            ...trelloData
        }, fields, licenseDetails)

        let cardsData = filteredCardData
        const isRecentEvalLicensed = isNewEvaluationLicensed(licenseDetails)
        if (isRecentEvalLicensed) {
            const archivedCards = filteredCardData.filter(card => card.archivedCard !== '')
            const nonArchivedCards = filteredCardData.filter(card => card.archivedCard === '')
            const maxCardsToExport = parseInt(process.env.REACT_APP_EVAL_EXPORT_PDF_CARDS || '5')
            const maxNonArchivedCardsToExport = parseInt(process.env.REACT_APP_PDF_MAX_NON_ARCHIVED_CARDS || '3')
            const maxArchivedCardsToExport = parseInt(process.env.REACT_APP_EVAL_PDF_MAX_ARCHIVED_CARDS || '2')
            cardsData = getCardsToExportForEvaluation(nonArchivedCards, archivedCards, maxCardsToExport, maxNonArchivedCardsToExport, maxArchivedCardsToExport)
        }

        const blob = await pdf(<PDFExportDocument cards={cardsData} selectedFields={fields} isOnlyEvalLicensed={isRecentEvalLicensed}/>).toBlob()
        const currentDate = (new Date()).toISOString().substring(0, 10).replace('-', '').replace('-', '')
        const fileName = trelloData.board.name + ' ' + currentDate + '.pdf'
        FileSaver.saveAs(blob, fileName)
    }
}

export default PDFExport;