    import React, {useEffect, useState} from 'react'
    import Modal, {ModalTransition} from '@atlaskit/modal-dialog'
    import Button from '@atlaskit/button'
    import {Box, Grid, Typography} from '@material-ui/core'
    import {typography} from '@atlaskit/theme'
    import styled from 'styled-components'
    import {Radio} from '@atlaskit/radio'
    import {useStyles} from './SelectFormatModalStyles'
    import {N500} from '@atlaskit/theme/colors'
    import Flag, {FlagGroup} from '@atlaskit/flag'
    import PDFExport from '../../modules/export/PDFExport'
    import ImageExport from '../../modules/export/ImageExport'
    import MSExcelExport from '../../modules/export/MSExcelExport'
    import CSVExport from '../../modules/export/CSVExport'
    import Spinner from '@atlaskit/spinner'
    import {TrackActionEvent} from 'trello-shared-resources/dist'
    import InfoIcon from '@atlaskit/icon/glyph/info'
    import {CheckboxSelect} from '@atlaskit/select'
    import {ValidationState} from '@atlaskit/select/types'
    import Form, {ErrorMessage, Field} from '@atlaskit/form'
    import {SelectedField} from '../../types/SelectedField'
    import {isNewEvaluationLicensed} from '../../modules/utility/Utils'

    /* istanbul ignore next */
    export const Heading = styled.div<{ mixin: any }>`${props => props.mixin};`

    const SelectFormatModal = (props: any) => {

        const classes = useStyles()

        const {
            licenseDetails,
            selected,
            trelloData,
            setSelectedListsToExportImg,
            setIsPreview,
            includeArchivedCards
        } = props
        trelloData.includeArchivedCards = includeArchivedCards
        const trelloContext = licenseDetails.trelloIframeContext
        const trelloContextInfo = trelloContext ? trelloContext.getContext() : undefined
        const selectedLists = selected.filter((list: any) => list.selected)
        const isRecentEvalLicensed = isNewEvaluationLicensed(licenseDetails)

        const [isOpen, setIsOpen] = useState(false)
        const [isOpenMenuSelect, setIsOpenMenuSelect] = useState(false)
        const [exportToPDF, setExportToPDF] = useState(true)
        const [exportToPNG, setExportToPNG] = useState(false)
        const [exportToXLS, setExportToXLS] = useState(false)
        const [exportToCSV, setExportToCSV] = useState(false)
        const [isExportInProgress, setIsExportInProgress] = useState(false)
        const [showNoListSelectedFlag, setShowNoListSelectedFlag] = useState<any>(null)
        const [fieldsByExportType, setFieldsByExportType] = useState<Array<{ label: string, value: string, isDisabled?: boolean, isOptionSelected?: boolean, isFixed?: boolean }>>([])
        const [fieldsSelected, setFieldsSelected] = useState<Array<SelectedField>>([])
        const [validationError, setValidationError] = useState<string | null>(null)

        const ARCHIVED_CARD_FIELD = {label: 'Archived card', value: 'archivedCard'}
        /**
         * Load fields to export by format
         */
        useEffect(() => {
            setValidationError(null)
            if (exportToPDF) {
                const pdfFields = [
                    {label: 'All fields', value: 'all'},
                    {label: 'Board Name', value: 'boardName', isDisabled: true, isOptionSelected: true, isFixed: true},
                    {label: 'List Name', value: 'listName', isDisabled: true, isOptionSelected: true, isFixed: true},
                    {label: 'Card Name', value: 'name', isDisabled: true, isOptionSelected: true, isFixed: true},
                    {label: 'Labels', value: 'labels'},
                    {label: 'Members', value: 'members'},
                    {label: 'Card Created Date', value: 'creationDate'},
                    {label: 'Due Date', value: 'dueDate'},
                    {label: 'Description', value: 'desc'},
                    {label: 'Checklists', value: 'checklists'},
                    {label: 'Comments', value: 'activity'},
                    {label: 'Custom Fields', value: 'customFieldItems'},
                    {label: 'Attachments', value: 'attachments'}
                ]
                setFieldsByExportType(pdfFields)
                if (fieldsSelected.length === 0) {
                    const pdfFieldsSelectedByDefault = pdfFields.slice(1, 4).map(field => {
                        const {isDisabled, isOptionSelected, ...cleanField} = field
                        return cleanField
                    })
                    setFieldsSelected(pdfFieldsSelectedByDefault)
                }
            } else if (exportToPNG) setFieldsByExportType([
                {label: 'Board', value: 'boardName'},
                {label: 'List Name', value: 'listName'},
                {label: 'Card Name', value: 'name'}
            ])
            else if (exportToCSV) setFieldsByExportType([
                {label: 'All fields', value: 'all'},
                {label: 'Card Name', value: 'name'},
                {label: 'Card ID', value: 'id'},
                {label: 'Short Card ID', value: 'idShort'},
                {label: 'Card URL', value: 'url'},
                {label: 'Board Name', value: 'boardName'},
                {label: 'Board ID', value: 'idBoard'},
                {label: 'List Name', value: 'listName'},
                {label: 'List ID', value: 'idList'},
                {label: 'Members', value: 'members'},
                {label: 'Labels', value: 'labels'},
                {label: 'Card Created Date', value: 'creationDate'},
                {label: 'Due Date', value: 'due'},
                {label: 'Description', value: 'desc'},
                {label: 'Custom Fields', value: 'customFieldItems'},
                {label: 'Checklists', value: 'checklists'},
                {label: 'Checklist Items Total', value: 'checklistItemTotal'},
                {label: 'Checklist Items Completed', value: 'checklistItemCompleted'},
                {label: 'Attachments', value: 'attachments'},
                {label: 'Location', value: 'address'},
                {label: 'Votes', value: 'votes'},
                {label: 'Number of Comments', value: 'comments'},
                {label: 'Last Activity Date', value: 'dateLastActivity'}
            ])
            else if (exportToXLS) setFieldsByExportType([
                {label: 'All fields', value: 'all'},
                {label: 'Card Name', value: 'name'},
                {label: 'Card ID', value: 'id'},
                {label: 'Short Card ID', value: 'idShort'},
                {label: 'Card URL', value: 'url'},
                {label: 'Board Name', value: 'boardName'},
                {label: 'Board ID', value: 'idBoard'},
                {label: 'List Name', value: 'listName'},
                {label: 'List ID', value: 'idList'},
                {label: 'Members', value: 'members'},
                {label: 'Labels', value: 'labels'},
                {label: 'Card Created Date', value: 'creationDate'},
                {label: 'Due Date', value: 'due'},
                {label: 'Description', value: 'desc'},
                {label: 'Custom Fields', value: 'customFieldItems'},
                {label: 'Checklists', value: 'checklists'},
                {label: 'Checklist Items Total', value: 'checklistItemTotal'},
                {label: 'Checklist Items Completed', value: 'checklistItemCompleted'},
                {label: 'Attachments', value: 'attachments'},
                {label: 'Location', value: 'address'},
                {label: 'Votes', value: 'votes'},
                {label: 'Comments', value: 'comments'},
                {label: 'Activity', value: 'activity'},
                {label: 'Last Activity Date', value: 'dateLastActivity'}
            ])

        }, [exportToPDF, exportToPNG, exportToXLS, exportToCSV, fieldsSelected])

        /**
         * Close the modal and clean the validation error
         */
        const closeModal = () => {
            setValidationError(null)
            setFieldsSelected([])
            setIsOpen(false)
            setIsOpenMenuSelect(false)
        }

        /**
         * Generate a track info object
         * @param exportType file format type
         * @param evaluation evaluation license or not
         * @return a track info object
         */
        const generateTrackInfo = (exportType: 'PDF' | 'CSV' | 'PNG' | 'Excel', evaluation: boolean) => {
            return {
                board_id: trelloContextInfo?.board,
                fields: fieldsSelected.map(fieldSelected => fieldSelected.value),
                include_archived_cards: includeArchivedCards,
                export_type: exportType,
                evaluation: evaluation
            }
        }

        /**
         * Get selected lists ids
         */
        const getSelectedListIds = (selected: any) => selected.filter((selectedList: any) => selectedList.selected).map((selectedList: any) => selectedList.id)

        /**
         * Export selected lists to PDF format
         */
        const exportPDF = async () => {
            const fields = trelloData.includeArchivedCards ? fieldsSelected.concat(ARCHIVED_CARD_FIELD) : Array.from(fieldsSelected)
            try {
                await new PDFExport().export(getSelectedListIds(selected), trelloData, fields, licenseDetails)
                TrackActionEvent('File Export', trelloContextInfo, generateTrackInfo('PDF', isRecentEvalLicensed))
            } catch (error) {
                console.error("Error when Export to PDF:", error);
            }
        }

        /**
         * Export selected lists to PNG format
         */
        const exportPNG = async () => {
            await new ImageExport().getSelectedLists(trelloContext, getSelectedListIds(selected), trelloData.includeArchivedCards, licenseDetails).then((lists: any) => {
                setSelectedListsToExportImg(lists)
                setIsPreview(true)
            })
            TrackActionEvent('File Export', trelloContextInfo, generateTrackInfo('PNG', isRecentEvalLicensed))
        }

        /**
         * Export selected lists and fields to XLS format
         */
        const exportXLS = async () => {
            const fields = trelloData.includeArchivedCards ? fieldsSelected.concat(ARCHIVED_CARD_FIELD) : Array.from(fieldsSelected)

            await new MSExcelExport(fields).export(getSelectedListIds(selected), trelloData, fields.filter(fieldSelected => fieldSelected.value !== 'all'), licenseDetails)
            TrackActionEvent('File Export', trelloContextInfo, generateTrackInfo('Excel', isRecentEvalLicensed))
        }

        /**
         * Export selected lists and fields to CSV format
         */
        const exportCSV = async () => {
            const fields = trelloData.includeArchivedCards ? fieldsSelected.concat(ARCHIVED_CARD_FIELD) : Array.from(fieldsSelected)

            await new CSVExport(fields).export(getSelectedListIds(selected), trelloData, fields.filter(fieldSelected => fieldSelected.value !== 'all'), licenseDetails)
            TrackActionEvent('File Export', trelloContextInfo, generateTrackInfo('CSV', isRecentEvalLicensed))
        }

        /**
         * Export, based on the selected format, the lists selected if it's valid.
         * Then render the download message and close the modal
         */
        const exportBoard = async () => {
            if (((exportToXLS || exportToCSV) && validate(fieldsSelected)) || exportToPNG || exportToPDF) {
                setIsExportInProgress(true)
                closeModal()
                if (exportToPDF) await exportPDF()
                else if (exportToPNG) await exportPNG()
                else if (exportToXLS) await exportXLS()
                else await exportCSV()
                setIsExportInProgress(false)
            }
        }

        /**
         * Store the format selected to export and restore validation errors and custom fields selected
         * @param setterSelected method to store the selected format to true
         */
        const selectFormatToExport = (setterSelected: any) => {
            if (!isOpenMenuSelect) {
                const exportSetters = [setExportToPDF, setExportToPNG, setExportToXLS, setExportToCSV]
                exportSetters.filter(setter => setter !== setterSelected)
                    .forEach(setterNotSelected => setterNotSelected(false))
                setterSelected(true)
                setValidationError(null)
                setFieldsSelected([])
            } else {
                setIsOpenMenuSelect(false)
            }
        }

        /**
         * Open modal window or render an error if no list are selected
         */
        const openModal = () => {
            if (selectedLists.length > 0) {
                setIsOpen(true)
            } else {
                renderNoListSelectedErrorMessage()
            }
        }

        /**
         * Show, if the message is not been showed, a message to say that you must select a list to export a file
         */
        const renderNoListSelectedErrorMessage = () => {
            if (!showNoListSelectedFlag) {
                setShowNoListSelectedFlag(<Flag
                    key={new Date().toISOString()}
                    appearance="error"
                    id="showNoListSelectedFlag"
                    icon={<InfoIcon label="Info" secondaryColor={N500}/>}
                    title="Please, select lists to export"/>
                )
                setTimeout(() => setShowNoListSelectedFlag(null), 3000)
            }
        }

        /**
         * Store the selected value and check if this is the all option
         * @param dropdownFieldsSelected an array or a value with the selected value
         */
        const fieldSelected = (fieldsSelected: any, actionMeta: any) => {
            if (exportToPDF && actionMeta.action === 'remove-value' && ['name', 'boardName', 'listName'].includes(actionMeta.removedValue.value)) {
                return
            }
            if (exportToPDF && actionMeta.action === 'clear') {
                setFieldsSelected(fieldsByExportType.filter(field => field.isFixed))
                return
            }

            if (fieldsSelected !== null && fieldsSelected.length > 0 &&
                fieldsSelected.find((fieldSelected: { label: string, value: string }) => fieldSelected.value === 'all')) {
                if (actionMeta.action === 'remove-value') setFieldsSelected(fieldsSelected.filter((field: any) => field.value !== 'all'))
                else setFieldsSelected(fieldsByExportType)
            } else if (Array.isArray(fieldsSelected)) setFieldsSelected(fieldsSelected)
            else if (actionMeta?.action === 'clear') setFieldsSelected([])
            else setFieldsSelected([fieldsSelected])
            validate(fieldsSelected)
        }

        /**
         * Return the state to render on the checkboxSelect
         */
        const getValidationState = (error: any, valid: boolean): ValidationState => {
            if (!validationError) {
                return 'default'
            }
            return 'error'
        }

        /**
         * Validate the given value, based on if it's an array or not. Also set a validation error if it isn't valid
         * @param value the option/options selected by the user
         */
        const validate = (value: any): boolean => {
            setValidationError(!value || (Array.isArray(value) && value.length === 0) ? 'EMPTY' : null)
            return (!Array.isArray(value) && value !== null && value !== undefined) || (Array.isArray(value) && value.length !== 0)
        }

        return (
            <>
                <Box display="flex" justifyContent="flex-end">
                    <Button appearance="primary" onClick={openModal}>Export Lists</Button>
                </Box>

                <FlagGroup>{showNoListSelectedFlag}</FlagGroup>
                <ModalTransition>
                    {isExportInProgress && (
                        <Modal
                            width="400px"
                            height="230px"
                        >
                            <Box className={classes.exportSpinner}>
                                <Heading mixin={typography.h400()} className={classes.exportPreferencesTitle}>Export in
                                    progress...</Heading>
                                <Spinner size={100}/>
                                <Typography className={classes.exportSpinnerGeneratingFileText}>We're creating your export
                                    file. It will be ready soon!</Typography>
                            </Box>
                        </Modal>
                    )}
                    {isOpen && (
                        <Modal
                            actions={[
                                {text: 'Export', onClick: exportBoard, testId: 'exportButton'},
                                {text: 'Cancel', onClick: closeModal, testId: 'cancelButton'}
                            ]}
                            onClose={closeModal}
                            width="400px"
                        >
                            <Heading mixin={typography.h400()} className={classes.exportPreferencesTitle}>Export
                                Preferences</Heading>
                            <Heading mixin={typography.h200()} className={classes.formatTitle}>Select an export
                                format:</Heading>
                            <Box>
                                <Box className={classes.exportTypeTitle}>
                                    <Heading mixin={typography.h100()}>Image</Heading>
                                </Box>
                                <Box className={classes.exportTypeContainer}>
                                    <Radio
                                        crossOrigin=""
                                        value="PDF"
                                        label="PDF"
                                        name="radio-PDF"
                                        isChecked={exportToPDF}
                                        onChange={(event) => selectFormatToExport(setExportToPDF)}
                                    />
                                    <Radio
                                        crossOrigin=""
                                        value="PNG"
                                        label="PNG"
                                        name="radio-PNG"
                                        isChecked={exportToPNG}
                                        onChange={(event) => selectFormatToExport(setExportToPNG)}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Box className={classes.exportTypeTitle}>
                                    <Heading mixin={typography.h100()}>Raw data</Heading>
                                </Box>
                                <Box className={classes.exportTypeContainer}>
                                    <Radio
                                        crossOrigin=""

                                        value="CSV"
                                        label="CSV"
                                        name="radio-CSV"
                                        isChecked={exportToCSV}
                                        onChange={(event) => selectFormatToExport(setExportToCSV)}
                                    />
                                    <Radio
                                        crossOrigin=""
                                        value="XLS"
                                        label="Excel"
                                        name="radio-XLS"
                                        isChecked={exportToXLS}
                                        onChange={(event) => selectFormatToExport(setExportToXLS)}
                                    />
                                </Box>

                            </Box>

                            {exportToPNG && (
                                <>
                                    <Heading mixin={typography.h200()} className={classes.formatTitle}>Fields included in
                                        your export format:</Heading>
                                    <Grid container className={classes.fieldsContainer}>
                                        {fieldsByExportType.map(field =>
                                            <Grid item xs={6} key={field.value}>
                                                <Typography className={classes.fieldLabel}>
                                                    {field.label}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                            )}

                            {(exportToCSV || exportToXLS || exportToPDF) && (
                                <>

                                    <Form onSubmit={
                                        /* istanbul ignore next */
                                        () => {}}>
                                        {({formProps}) => (
                                            <form {...formProps}>
                                                <Heading mixin={typography.h200()}
                                                         className={classes.formatTitle}>Fields</Heading>
                                                <Field name="fieldsToExport" isRequired>
                                                    {({fieldProps, error, meta: {valid}}: any) => (
                                                        <>
                                                            <CheckboxSelect
                                                                {...fieldProps}
                                                                className="checkbox-select"
                                                                classNamePrefix="select"
                                                                options={fieldsByExportType}
                                                                placeholder="Choose fields to export"
                                                                value={fieldsSelected}
                                                                menuPosition="fixed"
                                                                styles={{
                                                                    menuPortal:
                                                                    /* istanbul ignore next */
                                                                        base => ({
                                                                            ...base,
                                                                            zIndex: 9999
                                                                        }),
                                                                    multiValueRemove:
                                                                    /* istanbul ignore next */
                                                                        (base, state) => {
                                                                        return state.data.isFixed ? {
                                                                            ...base,
                                                                            display: 'none'
                                                                        } : base
                                                                    },
                                                                    multiValueLabel:
                                                                        /* istanbul ignore next */
                                                                        (base, state) => {
                                                                        return state.data.isFixed
                                                                            ? {...base, paddingRight: 6}
                                                                            : base
                                                                    }
                                                                }}
                                                                menuPortalTarget={document.body}
                                                                validationState={getValidationState(error, valid)}
                                                                onChange={fieldSelected}
                                                                onMenuOpen={() => {
                                                                    setIsOpenMenuSelect(true)
                                                                }}
                                                                onMenuClose={() => {
                                                                    setIsOpenMenuSelect(false)
                                                                }}
                                                            />
                                                            {validationError === 'EMPTY' && (
                                                                <ErrorMessage>Please select at least one
                                                                    item.</ErrorMessage>
                                                            )}
                                                        </>
                                                    )}
                                                </Field>
                                            </form>
                                        )}
                                    </Form>
                                </>
                            )}
                        </Modal>
                    )}
                </ModalTransition>
            </>
        )
    }

    export default SelectFormatModal
