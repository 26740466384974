import DataFormatter from 'trello-shared-resources/dist/modules/formatting/DataFormatter'
import {findActionsByCards, getArchivedCards, getChecklistsByCards} from '../Persistence'

/**
 * Alters Data from Trello into a format that is desirable for exportation.
 */
class DataFormatterImpl extends DataFormatter {

    constructor(fieldsToOutput, contentTransformers, contentExpanders, contentAppenders) {
        super(fieldsToOutput, contentTransformers, contentExpanders)
        this.contentAppenders = []

        if (contentAppenders) {
            this.contentAppenders = contentAppenders
        }
    }

    /**
     * Given an array of Trello lists we build a list of card data 'objects' that we want to be converted to CSV. These
     * objects are pre-formatted to include only the fields we want to see in the final output.
     *
     * Fields that are used are dictated by the static FIELDS_TO_OUTPUT at the top of the class.
     *
     * @param {*} inputTrelloData data coming from Trello that we need to format.
     * @param {*} fieldsSelected A list of objects {label, value} with the selected fields to export
     * @param licenseDetails object to get current memberId and trello app key
     * @returns An array of 'objects' containing the the data we will convert to CSV format.
     */
    async getCardDataFromLists(inputTrelloData, fieldsSelected, licenseDetails) {
        let cardData = []
        const listCards = []

        // collecting cards
        for (const list of inputTrelloData.list) {
            listCards.push(...list.cards)
        }
        if (inputTrelloData.includeArchivedCards) {
            const allArchivedCards = await getArchivedCards(licenseDetails)
            listCards.push(...allArchivedCards)
        }

        let actionsByCards = undefined
        if (fieldsSelected.some(item => ['activity', 'comments', 'creationDate'].includes(item))) {
            actionsByCards = await findActionsByCards(listCards, licenseDetails.apiKey)
        }
        const checklists = fieldsSelected.includes('checklists') ? await getChecklistsByCards(listCards, inputTrelloData.apiKey, inputTrelloData.token) : []

        for (const card of listCards) {
            let cardDataObject = {}
            let trelloData = {
                ...inputTrelloData,
                checklists: checklists,
                list: inputTrelloData.list.find(list => list.id === card.idList),
                actionsByCards: actionsByCards ? actionsByCards[card.id] : [],
                card: card,
            }

            this._loadCardDisplayPropertiesIntoDataObject(trelloData, cardDataObject)
            if (fieldsSelected.includes('customFieldItems')) this._loadCustomFieldNames(inputTrelloData.board, cardDataObject)
            this._loadExpandedPropertiesIntoDataObject(trelloData, cardDataObject)
            this._loadAppendedProperties(cardDataObject, trelloData, fieldsSelected)
            cardData.push(cardDataObject)
        }

        return cardData
    }

    _loadAppendedProperties(cardDataObject, trelloData, fieldsSelected) {
        this.contentAppenders.forEach(appender => {
            appender(cardDataObject, trelloData, fieldsSelected)
        })
    }
}

export default DataFormatterImpl