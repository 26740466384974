export function calculatePercentageForChecklist(totalNoOfItems, numberInDesiredState) {
    if (numberInDesiredState === 0) {
        return 0
    }    
    if (totalNoOfItems && numberInDesiredState) {
        return Math.round((numberInDesiredState / totalNoOfItems) * 100)
    }
}

/**
 * Get an array with custom field names from the list of card data given
 * @param {Array} listOfCardData A list of card data that we want to convert to XSLX format.
 * @return {string[]} string array with custom field names
 */
export function getCustomFieldNames(listOfCardData) {
    let customFieldNames = listOfCardData.map(cardData => (
        Object.keys(cardData).filter(cardFieldName => cardFieldName.startsWith('Custom Field:'))
    )).flat()
    if(customFieldNames && customFieldNames.length > 0) {
        customFieldNames = customFieldNames.filter(customFieldName => customFieldName)
        return [...new Set(customFieldNames)]
    }
    return []
}

/**
 * Get an array with checklist field names from the list of card data given
 * @param {Array} listOfCardData A list of card data that we want to convert to XSLX format.
 * @return {string[]} string array with checklist field names
 */
export function getChecklistFieldNames(listOfCardData) {
    let checklistFieldNames = listOfCardData.map(cardData => (
        Object.keys(cardData).filter(cardFieldName =>
            cardFieldName.toLowerCase().startsWith('checklist') && cardFieldName !== 'checklistItemTotal' && cardFieldName !== 'checklistItemCompleted')
    )).flat()
    if(checklistFieldNames && checklistFieldNames.length > 0) {
        checklistFieldNames = checklistFieldNames.filter(checklistFieldName => checklistFieldName)
        return [...new Set(checklistFieldNames)]
    }
    return []
}