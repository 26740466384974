export const CustomisationValuesEnum = Object.freeze({
    CUSTOM_FIELDS_KEY: 'customFieldsCustomisation',
    CHECKLISTS_KEY: 'checklistsCustomisation'
})

/**
 * Get all customisation data from the current member
 *
 */
export const getAllCustomisationData = async () => {
    // @ts-ignore
    const trelloContext = window.TrelloPowerUp.iframe()

    const customisationData = {}
    for (const key in CustomisationValuesEnum) {
        // @ts-ignore
        const value = CustomisationValuesEnum[key]

        // @ts-ignore
        customisationData[value] = await trelloContext.get('member', 'private', value, false)
    }
    return customisationData
}