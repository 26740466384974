import {useEffect} from 'react'
import {initializeCapabilities, PageView, TrackActionEvent,} from 'trello-shared-resources'
import {showDarkThemeNotSupportedMessage} from 'trello-shared-resources/dist/services/DarkThemeService'

/**
 *   The Connector is used to tell Trello what capabilities your power-up should have.
 *   This Connector should be used to add new menu items, buttons and other similar capabilities.
 */
const Connector = () => {
    const capabilities = {
        'board-buttons': function (t, options) {
            return [{
                text: 'Board Export',
                icon: './icons/board-export-icon-mono.svg',
                // Board buttons need their own modal function
                callback: function (trelloContext, opts) {
                    try {
                        const trelloContextInfo = trelloContext.getContext()
                        TrackActionEvent('Button Click', trelloContextInfo, {
                            board_id: trelloContextInfo.board,
                            button: 'Open Board_Export'
                        })
                        showDarkThemeNotSupportedMessage(trelloContext)

                        return trelloContext.modal({
                            url: './index.html?apptype=exportmodal',
                            accentColor: '#EBEDF0',
                            fullscreen: true,
                            resizable: false,
                            title: 'Board Export by Kolekti',
                            callback: function (t, opts) {
                                const trelloContextInfo = t.getContext()
                                TrackActionEvent('Button Click', trelloContextInfo, {
                                    board_id: trelloContextInfo.board,
                                    button: 'Close Board_Export'
                                })
                            }
                        })
                    }catch (error) {
                        if(!error.message.includes('PostMessageIO:PluginDisabled')) throw error
                    }
                }
            }]
        }
    }

    useEffect(() => {
        PageView('PowerUp Connector')
    }, [])

    initializeCapabilities(capabilities)

    return null
}

export default Connector
