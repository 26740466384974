/**
 * Get cards to be exported when a user is using a evaluation license
 * @param nonArchivedCards
 * @param archivedCards
 * @param maxCardsToExport
 * @param maxNonArchivedCardsToExport
 * @param maxArchivedCardsToExport
 * @return list of cards to display
 */
export function getCardsToExportForEvaluation(nonArchivedCards: Array<any>, archivedCards: Array<any>, maxCardsToExport: number, maxNonArchivedCardsToExport: number, maxArchivedCardsToExport: number) {
    if(nonArchivedCards.length >= maxNonArchivedCardsToExport && archivedCards.length >= maxArchivedCardsToExport)
        return [...nonArchivedCards.slice(0, maxNonArchivedCardsToExport), ...archivedCards.slice(0, maxArchivedCardsToExport)]

    if(nonArchivedCards.length + archivedCards.length >= (maxNonArchivedCardsToExport + maxArchivedCardsToExport))
        return [
            ...(nonArchivedCards.length < archivedCards.length ? nonArchivedCards : nonArchivedCards.slice(0, maxCardsToExport - archivedCards.length)),
            ...(archivedCards.length < nonArchivedCards.length ? archivedCards : archivedCards.slice(0, maxCardsToExport - nonArchivedCards.length))
        ]

    return [...nonArchivedCards, ...archivedCards]
}

/**
 * Check if user is using an eval license and that eval license has been created since the release date for evaluation limitations
 * @param licenseDetails
 * @return true if user is using an eval license and the eval license has been created after the release date for evaluation limitations or false otherwise
 */
export function isNewEvaluationLicensed(licenseDetails: any) {
    //TODO remove after 06/20, cause will be not needed to check dates, as all evaluations will be new https://trello.com/c/ioddHUwl/577-remove-the-date-check-for-new-evals
    const millis14Days = 14 * 24 * 60 * 60 * 1000
    const millisEvalsLimitReleaseDate = Date.parse(process.env.REACT_APP_EVAL_RELEASE_DATE!)
    return licenseDetails.isOnlyEvalLicensed() && (licenseDetails.licenseCapabilitiesEndDates['Evaluation'] - millis14Days) > millisEvalsLimitReleaseDate
}

/**
 * Collect the lists with cards to export for the PNG format
 * @param evaluationCards cards to export for evaluation
 * @param totalList all lists to export by default
 * @return array of List that contains the cards to show for evaluation licenses
 */
export function collectCardsToPNGExportForEvaluation(evaluationCards: Array<any>, totalList: Array<any>) {
    const evaluationLists: Array<any> = []
    evaluationCards.forEach(evaluationCard => {
        const listToAdd = totalList.find(list => list.cards.find((card: any) => card.id === evaluationCard.id))
        const listAlreadyAdded = evaluationLists.find((evaluationList: any) => evaluationList.id === listToAdd.id)
        if(listAlreadyAdded){
            listAlreadyAdded.cards.push(evaluationCard)
        } else {
            evaluationLists.push({...listToAdd, cards: [evaluationCard]})
        }
    })
    return evaluationLists
}