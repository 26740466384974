import React from 'react'
import {Text, View} from '@react-pdf/renderer'
import {PDFExportPageCustomFieldItemProperty} from '../../../../types/PDFExportTypes'
import PDFExportPageCustomFieldIcon from './PDFExportPageCustomFieldIcon'
import PDFExportPageCustomFieldValue from './PDFExportPageCustomFieldValue'

/**
 * Render all given custom fields
 * @param props customFields and styles
 */
function PDFExportPageCustomFieldItem(props: PDFExportPageCustomFieldItemProperty) {
    const {customField, style} = props
    return (
        <View style={[style.customFieldContainer, style.customFieldRow]}>
            <View style={style.customFieldNameContainer}>
                <View style={style.customFieldImage}>
                    <PDFExportPageCustomFieldIcon {...props}/>
                </View>
                <Text style={style.customFieldName}>{customField.name.toUpperCase()}</Text>
            </View>
            <PDFExportPageCustomFieldValue {...props}/>
        </View>)
}

export default PDFExportPageCustomFieldItem