import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles( {
    exportSpinner: {
        margin: '20px',
        textAlign: 'center'
    },
    exportSpinnerGeneratingFileText: {
        marginTop: 10
    },
    exportPreferencesTitle: {
        fontFamily: 'SF Pro Text Semibold',
        fontSize: 14,
        marginTop: 24,
        marginBottom: 10
    },
    formatTitle: {
        fontFamily: 'SF Pro Text Semibold',
        marginBottom: 4,
        weight: '600'
    },
    exportTypeTitle: {
        marginLeft: 8
    },
    exportTypeContainer: {
        marginLeft: 12
    },
    fieldsContainer: {
        padding: '7px 4px 4px 4px'
    },
    fieldLabel: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '14px',
        lineHeight: '20px',
        marginTop: 4,
        letterSpacing: '0px'
    }
})


export { useStyles }
