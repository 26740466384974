import {calculatePercentageForChecklist} from './Calculations'
import {dateTransformer} from "./Transformers"

/**
 * Appends raw checklist data to the cardDataObject.
 * @param {*} cardDataObject
 * @param {*} trelloData
 */
export function pdfChecklistDataAppender(cardDataObject, trelloData) {
    _verifyInputAndAppendChecklistData(cardDataObject, trelloData, ['checklistTotal', 'checklistCompleted', 'checklists'], _appendRawChecklistData)
}

function _appendRawChecklistData(checklist, cardDataObject, trelloData) {
    const checklistResult = cardDataObject['checklists'] && cardDataObject['checklists'].length > 0 ? cardDataObject['checklists'] : []
    const incompleteItems = checklist.checkItems
        .filter((checkItem) => checkItem.state === 'incomplete')
    const completeItems = checklist.checkItems
        .filter((checkItem) => checkItem.state === 'complete')

    const allItems = checklist.checkItems.map((checkItem) => {
        const output = expandRawChecklistItem(checkItem, trelloData)
        return {
            ...checkItem,
            ...output
        }
    }).sort((checkItem1, checkItem2) => checkItem1.pos - checkItem2.pos)

    const percentageComplete = calculatePercentageForChecklist(checklist.checkItems.length, completeItems.length)
    const percentageIncomplete = calculatePercentageForChecklist(checklist.checkItems.length, incompleteItems.length)

    const checklistItems = {
        name: checklist.name,
        allItems: allItems,
        percentageComplete: percentageComplete,
        percentageIncomplete: percentageIncomplete
    }

    checklistResult.push(checklistItems)
    cardDataObject['checklists'] = checklistResult
}

function expandRawChecklistItem(checkItem, trelloData) {
    let itemName = checkItem.name
    const dueDate = checkItem.due
    const memberId = checkItem.idMember

    let output = {
        name: itemName,
    }

    if (dueDate) {
        output.due = _formatOutputDueDate(dueDate)
    }

    const member = _getMemberNameFromId(memberId, trelloData.members)
    if (member) {
        output.member = member
    }

    return output
}

/**
 * Appends pre-formatted checklist data to the cardDataObject.
 * @param {*} cardDataObject
 * @param {*} trelloData
 */
export function checklistDataAppender(cardDataObject, trelloData, fieldSelected) {
    _verifyInputAndAppendChecklistData(cardDataObject, trelloData, fieldSelected, _appendChecklistItems)
}

/**
 * Appends pre-formatted checklist data to the cardDataObject.
 * @param {*} cardDataObject
 * @param {*} trelloData
 */
export function checklistExcelDataAppender(cardDataObject, trelloData, fieldSelected) {
    _verifyInputAndAppendChecklistData(cardDataObject, trelloData, fieldSelected, _appendExcelChecklistItems)
}

function _verifyInputAndAppendChecklistData(cardDataObject, trelloData, fieldSelected, appendCallback) {
    const checklistData = trelloData.checklists

    if (checklistData && checklistData.length > 0) {

        if (fieldSelected.includes('checklistItemTotal')) {
            _checklistItemsTotalExpander(cardDataObject, trelloData)
        }
        if (fieldSelected.includes('checklistItemCompleted')) {
            _checklistItemsCompletedExpander(cardDataObject, trelloData)
        }
        const cardId = trelloData.card?.id
        const checklistsForCard = checklistData
            .filter((checklist) => checklist.idCard && checklist.idCard === cardId)
            .sort((checklist1, checklist2) => checklist1.pos - checklist2.pos)

        if (checklistsForCard && checklistsForCard.length > 0 && fieldSelected.includes('checklists')) {
            _appendAllChecklistItems(checklistsForCard, cardDataObject, trelloData, appendCallback)
        }
    }
}

function _appendAllChecklistItems(checklists, cardDataObject, trelloData, appendCallback) {
    checklists.forEach(checklist => {
        appendCallback(checklist, cardDataObject, trelloData)
    })
}

function _appendChecklistItems(checklist, cardDataObject, trelloData) {
    ['complete', 'incomplete'].forEach(state => {
        const items = []
        const itemsOfState = checklist.checkItems
            .filter((checkItem) => checkItem.state === state)

        if (itemsOfState.length < 1) {
            return
        }


        const progressPercentage = calculatePercentageForChecklist(checklist.checkItems.length, itemsOfState.length)

        itemsOfState
            .sort((checkItem1, checkItem2) => checkItem1.pos - checkItem2.pos)
            .forEach(item => {
            items.push(`${generateItemName(item, trelloData)}`)
        })

        const displayState = state.charAt(0).toUpperCase() + state.slice(1)
        cardDataObject[`Checklist: ${checklist.name} [${displayState}] (${progressPercentage}%)`] = items.join()
    })
}

/**
 * Avoid to have repeated checklist names in the export
 *
 * @param checklistName
 * @param cardDataObject
 */
function repeatedChecklistName(checklistName, cardDataObject) {
    if (checklistName in cardDataObject) {
        const checklistNameRepeated = 2 + Object.keys(cardDataObject).filter(cardDataAttrName => cardDataAttrName.startsWith(checklistName + ' #')).length
        checklistName += ' #' + checklistNameRepeated
    }
    return checklistName
}

/**
 * Fill the given cardDataObject with the checklist info
 * @param checklist object that contains all selected checklist
 * @param cardDataObject the object to fill
 * @param trelloData object that contains all needed info to export, like members, lists, cards...
 * @private
 */
function _appendExcelChecklistItems(checklist, cardDataObject, trelloData) {
    if (checklist.checkItems.length < 1) {
        return
    }

    const checkItems = checklist.checkItems.sort((checkItem1, checkItem2) => checkItem1.pos - checkItem2.pos)
    const completedItems = checkItems.filter((checkItem) => checkItem.state === 'complete')
    const incompleteItems = checkItems.filter((checkItem) => checkItem.state === 'incomplete')
    const progressPercentage = calculatePercentageForChecklist(checkItems.length, completedItems.length)

    let checklistName = `Checklist: ${checklist.name}`

    // separated Checklists into individual cells in the final export.
    if (trelloData.customisationData && trelloData.customisationData.checklistsCustomisation) {
        checklistName = repeatedChecklistName(`Checklist: ${checklist.name} [Complete] (${progressPercentage}%)`, cardDataObject)
        cardDataObject[checklistName] = {
            richText: [
                {
                    text: generateChecklistItemsInSeparatedCells(completedItems, trelloData),
                    font: {
                        name: 'SF Pro Text Regular',
                        size: 14
                    }
                }
            ]
        }
        checklistName = repeatedChecklistName(`Checklist: ${checklist.name} [Incomplete] (${100 - progressPercentage}%)`, cardDataObject)
        cardDataObject[checklistName] = {
            richText: [
                {
                    text: generateChecklistItemsInSeparatedCells(incompleteItems, trelloData),
                    font: {
                        name: 'SF Pro Text Regular',
                        size: 14
                    }
                }
            ]
        }
        return
    }

    checklistName = repeatedChecklistName(checklistName, cardDataObject)
    cardDataObject[checklistName] = {
        richText: [
            {
                text: 'Total:',
                font: {
                    bold: true,
                    name: 'SF Pro Text Regular',
                    size: 14
                }
            },
            {
                text: generateItemNameTotal(checklist.checkItems),
                font: {
                    name: 'SF Pro Text Regular',
                    size: 14
                }
            },
            {
                text: 'Complete:',
                font: {
                    bold: true,
                    name: 'SF Pro Text Regular',
                    size: 14
                }
            },
            {
                text: generateItemNameByStatus(completedItems, progressPercentage, 'Complete', trelloData),
                font: {
                    name: 'SF Pro Text Regular',
                    size: 14
                }
            },
            {
                text: 'Incomplete:',
                font: {
                    bold: true,
                    name: 'SF Pro Text Regular',
                    size: 14
                }
            },
            {
                text: generateItemNameByStatus(incompleteItems, 100 - progressPercentage, 'Incomplete', trelloData),
                font: {
                    name: 'SF Pro Text Regular',
                    size: 14
                }
            }
        ]
    }
}

/**
 * Generate a string with the number of items and a list of all of them
 * @param checkItems {Array<any>} all checklist items for a card checklist
 * @return {string} the generated string
 */
export function generateItemNameTotal(checkItems) {
    return ` ${checkItems.length} (${checkItems.map(checkItem => checkItem.name).join(', ')}),\n`
}

/**
 * Generate
 *  - If any checklist item has due date or member assigned, format will be:
 *       50%,
 *       checklist item Completed; January 01, 2021; John Doe,
 *       checklist item2 Completed; January 02, 2021,
 *       checklist item2 Completed; John Doe,
 *  - Otherwise format will be:
 *      50% (checklist item Completed, checklist item Completed2)
 *
 * @param checkItems {Array<any>} all checklist items for a card checklist
 * @param progressPercentage {number} completion percentage
 * @param status {string} completed / incomplete
 * @param trelloData object that contains all needed info to export, like members, lists, cards...
 * @return {string} the generated string
 */
export function generateItemNameByStatus(checkItems, progressPercentage, status, trelloData) {
    if (checkItems.some(checkItem => checkItem.due || checkItem.idMember)) {
        return ` ${progressPercentage}%,\n${checkItems.map(checkItem => `${checkItem.name} - ${status}; ${checkItem.due ? `${dateTransformer(checkItem.due)}${_getMemberNameFromId(checkItem.idMember, trelloData.members) ? '; ' : ''}` : ''}${_getMemberNameFromId(checkItem.idMember, trelloData.members)}`).join(',\n')}${status === 'Complete' ? ',\n' : ''}`
    } else {
        return ` ${progressPercentage}% ${checkItems && checkItems.length > 0 ? `(${checkItems.map(checkItem => checkItem.name).join(', ')})${status === 'Complete' ? ',' : ''}` : ''}\n`
    }
}

/**
 * Generate
 *  - If any checklist item has due date or member assigned, format will be:
 *       checklist item Completed; January 01, 2021; John Doe, checklist item2 Completed; January 02, 2021
 *  - Otherwise format will be:
 *      checklist item Completed, checklist item Completed2
 *
 * @param checkItems {Array<any>} all checklist items for a card checklist
 * @param trelloData object that contains all needed info to export, like members, lists, cards...
 * @return {string} the generated string
 */
function generateChecklistItemsInSeparatedCells(checkItems, trelloData) {
    return `${checkItems.map(checkItem => `${checkItem.name}${checkItem.due ? ` (${dateTransformer(checkItem.due)})` : ''}${_getMemberNameFromId(checkItem.idMember, trelloData.members) ? `(${_getMemberNameFromId(checkItem.idMember, trelloData.members)})` : ''}`)
        .join(', ')}`
}

export function generateItemName(item, trelloData) {
    let itemName = item.name
    const dueDate = item.due
    const memberId = item.idMember

    if (dueDate) {
        itemName = itemName + ` (${_formatOutputDueDate(dueDate)})`
    }

    if (trelloData) {
        const member = _getMemberNameFromId(memberId, trelloData.members)
        if (member) {
            itemName = itemName + `${dueDate ? '' : ' '}(${member})`
        }
    }

    return itemName
}

function _formatDateNumber(number) {
    return ('0' + number).slice(-2)
}

function _formatOutputDueDate(dueDate) {
    const date = new Date(dueDate)
    const dueDateDate = `${_formatDateNumber(date.getDate())}-${_formatDateNumber(date.getMonth() + 1)}-${date.getFullYear()}`
    const dueDateTime = `${_formatDateNumber(date.getUTCHours())}:${_formatDateNumber(date.getUTCMinutes())}:${_formatDateNumber(date.getUTCSeconds())}`
    return `${dueDateDate} ${dueDateTime}`
}

/**
 * Get member full name from the given list of members by the given member id
 * @param memberId {string} member identifier
 * @param members {Array<any>} list of board members
 * @return {string} full name if we can find user by id or empty string otherwise
 */
function _getMemberNameFromId(memberId, members) {
    if (memberId && members.length > 0) {
        const membership = members.find((membership) => membership.idMember === memberId && membership.member)
        return membership ? membership.member.fullName : ''
    } else return ''
}

/**
 * Fill cardDataObject with the total number of checklist items for a card
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 */
function _checklistItemsTotalExpander(cardDataObject, trelloData) {
    if (trelloData.card?.badges) {
        const totalItemsByCard = trelloData.card.badges.checkItems
        cardDataObject['checklistItemTotal'] = totalItemsByCard
    }
}

/**
 * Fill cardDataObject with the total number of checklist items completed for a card
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 */
function _checklistItemsCompletedExpander(cardDataObject, trelloData) {
    if (trelloData.card?.badges) {
        const checkItemsCheckedByCard = trelloData.card.badges.checkItemsChecked
        cardDataObject['checklistItemCompleted'] = checkItemsCheckedByCard
    }
}

