import React, {Component} from 'react'
import {Skeleton} from 'antd'
import BoardExportDialog from '../components/boardExportDialog/BoardExportDialog'
import {LicenseDetailsContext, LicenseDetailsProvider, PageView,} from 'trello-shared-resources/dist'

class BoardExport extends Component {
    state = { selectedMenuItem: this.props.selectedMenuItem }

    componentDidMount() {
        PageView('BoardExport')
    }

    render() {
        return (
            <LicenseDetailsProvider>
                <LicenseDetailsContext.Consumer>
                    {(licenseDetails) => {
                        if (licenseDetails.isLoading) {
                            return <Skeleton active={true}/>
                        }
                        return <BoardExportDialog licenseDetails={licenseDetails} selectedMenuItem={this.state.selectedMenuItem}/>
                    }}
                </LicenseDetailsContext.Consumer>
            </LicenseDetailsProvider>
        )
    }
}

export default BoardExport