import {StyleSheet} from '@react-pdf/renderer'


export const fontFamilyJapanese = 'MPLUSRounded1c'
export const fontFamilyArabic = 'NotoSansArabic'
export const fontFamilyChinese = 'Noto Sans Simplified Chinese'
export const fontFamilyOtherLanguages = 'Roboto'
export const fontFamilyPortugueseOrFrench = 'Raleway'
export const fontFamilyMono = 'robotomono'


export const createStyle = (fontNames: string[]) => {

    const fontFamily = fontNames[0]

    return StyleSheet.create({
        h1: {
            marginBottom: 20,
            marginHorizontal: 40,
            color: '#000000',
            fontFamily: fontFamily,
            fontWeight: 'bold',
            fontSize: 24,
        },
        h2: {
            marginLeft: 4,
            marginTop: 1,
            marginBottom: 18,
            color: '#000000',
            fontFamily: fontFamilyOtherLanguages,
            fontWeight: 'bold',
            fontSize: 18,
        },
        marginHorizontalInPage: {
            marginHorizontal: 40
        },
        paddingVerticalInPage: {
            paddingVertical: 20
        },
        topPageView: {
            flexDirection: 'row'
        },
        pageSubtitleView: {
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        pageTitleSubtitle: {
            color: 'rgb(134, 134, 134)',
            paddingVertical: 10,
            marginHorizontal: 20,
            fontFamily: fontFamily,
            fontSize: 14
        },
        bannerItemRow: {
            color: '#000000',
            fontFamily: fontFamilyOtherLanguages,
            fontSize: 14,
            flexDirection: 'row'
        },
        customFieldIcon: {
            marginTop: 2,
            width: 14,
            height: 14
        },
        customFieldRow: {
            color: '#000000',
            fontFamily: fontFamily,
            fontSize: 14,
        },
        customFieldContainer: {
            // @ts-ignore
            flexBasis: '33%',
            display: 'flex',
            marginBottom: '20px'
        },
        customFieldNameContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        customFieldImage: {
            flexGrow: 1,
            flex: 1
        },
        customFieldName: {
            flexGrow: 1,
            flex: 9,
            color: '#5E6C84'
        },
        bannerItemCol: {
            marginBottom: 10
        },
        labelDetailItemRow: {
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            flexGrow: 1,
        },
        cardDetailItemRow: {
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            flexGrow: 1,
            marginBottom: 10,
        },
        cardDetailItemLabel: {
            fontFamily: fontFamilyOtherLanguages,
            fontWeight: 'bold',
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 115,
        },
        cardDetailItemValue: {
            flexGrow: 1,
        },
        descriptionIcon: {
            marginLeft: 20,
            marginTop: 4,
            width: 16,
            height: 16
        },
        bannerItemCell: {
            margin: 'auto',
            width: 115,
            fontWeight: 'bold',
            textAlign: 'left'
        },
        bannerItemData: {
            fontWeight: 500,
            fontFamily: fontFamily,
            textAlign: 'left'
        },
        labelItemRect: {
            marginBottom: 5,
            marginRight: 15,
            borderRadius: 5
        },
        labelItemText: {
            fontFamily: fontFamily,
            margin: 2,
            marginRight: 15,
            marginLeft: 15,
            color: 'white',
            fontSize: 12,
            fontWeight: 600,
            alignItems: 'center'
        },
        customFieldListItemNoColorText: {
            fontFamily: fontFamily,
            marginTop: 2,
            color: '#172B4D',
            fontSize: 14,
        },
        text: {
            fontFamily: fontFamily,
            fontSize: 14,
            textAlign: 'justify',
            marginBottom: 10
        },
        bannerItemRowChecklist: {
            marginRight: 80,
        },
        completionPercentage: {
            fontFamily: fontFamily,
            fontSize: 14,
            marginRight: 10,
            marginBottom: 20
        },
        partLoaded: {
            borderBottomLeftRadius: 3,
            borderTopLeftRadius: 3,
            height: 6,
            marginTop: 5,
            backgroundColor: '#0065FF',
            borderColor: '#0065FF',
        },
        rightRadius: {
            borderBottomRightRadius: 3,
            borderTopRightRadius: 3,
        },
        leftRadius: {
            borderBottomLeftRadius: 3,
            borderTopLeftRadius: 3,
        },
        partNotLoaded: {
            borderBottomRightRadius: 3,
            borderTopRightRadius: 3,
            height: 6,
            marginTop: 5,
            flex: 1,
            flexWrap: 'wrap',
            backgroundColor: 'lightgrey',
            borderColor: 'lightgrey',
        },
        attachmentsContainer: {
            display: 'flex'
        },
        attachmentContainer: {
            flexGrow: 1,
            flexDirection: 'row',
            flex: 1,
            flexWrap: 'wrap',
            marginTop: 10,
            marginBottom: 10,
            display: 'flex'
        },
        attachmentImageContainer: {
            width: '70px',
            minHeight: '40px'
        },
        attachmentImage: {
            margin: 'auto',
            height: '40px'
        },
        attachmentInfoContainer: {
            flexDirection: 'column',
            flexGrow: 10,
            marginLeft: '16px'
        },
        attachmentNameContainer: {
            flexGrow: 1,
            flexDirection: 'column'
        },
        attachmentSpaceNameContainer: {
            flexGrow: 1
        },
        attachmentBoxToFormat: {
            flexGrow: 1,
            flex: 1,
            display: 'flex'
        },
        attachmentDateContainer: {
            flexGrow: 1,
            marginLeft: '1px'
        },
        attachmentNameText: {
            fontFamily: fontFamily,
            fontWeight: 'bold',
            fontSize: 14,
            color: '#000000',
            textDecoration: 'none'
        },
        attachmentDateText: {
            fontFamily: fontFamily,
            fontSize: 14,
            color: '#000000',
            textDecoration: 'none'
        },
        checklistElementText: {
            fontFamily: fontFamily,
            fontSize: 14,
            marginTop: 2,
            color: '#172B4D',
        },
        checklistSectionTitle: {
            marginLeft: 4,
            marginTop: 1,
            marginBottom: 18,
            fontWeight: 'bold',
            fontFamily: fontFamily,
            fontSize: 18,
        },
        checklistElementDueDateRect: {
            margin: 2,
            marginLeft: 5,
            width: 82,
            flex: 1,
            flexWrap: 'wrap',
            borderRadius: 5,
            marginRight: 40
        },
        checklistElementDueDateText: {
            fontFamily: fontFamily,
            paddingHorizontal: 5,
            paddingVertical: 2,
            textAlign: 'center',
            color: 'white',
            fontSize: 14,
        },
        checkBoxIcon: {
            marginRight: 15,
            marginLeft: 45,
            marginVertical: 5,
            padding: 0,
            width: 14,
            height: 14
        },
        activityCreator: {
            fontSize: 14,
            fontFamily: fontFamily,
            fontWeight: 'bold',
            marginBottom: 12
        },
        activityDate: {
            marginLeft: 5,
            marginTop: 2,
            fontSize: 12,
            fontFamily: fontFamily,
            fontWeight: 'light'
        },
        activityText: {
            marginTop: 12,
            marginBottom: 16,
            fontSize: 12,
            fontFamily: fontFamily,
            padding: 12,
            border: '1 solid #979797'
        },
        dueDateRect: {
            marginLeft: 15,
            borderRadius: 5
        },
        dueDateCheckBoxIcon: {
            marginRight: 15,
            marginTop: 2,
            width: 14,
            height: 14
        },
        description: {
            fontFamily: fontFamily,
            fontSize: '14'
        },
        p: {

        },
        strong: {
            fontFamily: fontFamily,
            fontWeight: 'bold',
        },
        link: {
            color: '#0044ff',
            textDecoration: 'underline'
        },
        list: {
        },
        listItem: {
        },
        br: {

        },
        orderedListItem: {
            marginLeft: '50px',
            paddingLeft: 50
        },
        codeBlock: {
            fontFamily: 'robotomono',
            fontSize: 14,
            backgroundColor: 'lightgrey',
            borderRadius: 3,
            padding: '8px 12px 8px 12px',
            margin: '12px 8px 12px 8px'
        },
        inlineCodeBlock: {
            fontFamily: 'robotomono',
            fontSize: 14,
            backgroundColor: 'lightgrey',
            color: '#eb5a46'
        },
        blockquote: {
            borderLeft: '1px solid #ebebeb',
            color: '#5e6c84',
            marginLeft: 11,
            paddingLeft: 12,
            paddingTop: '-12px'
        },
        hr: {
            margin: '16px 0',
            borderTop: '1px solid #ebebeb'
        },
        em: {
            fontFamily: fontFamilyOtherLanguages,
            fontStyle: 'italic'
        },
        h3: {
            marginLeft: 4,
            marginTop: 1,
            marginBottom: 18,
            fontFamily: fontFamily,
            fontWeight: 'bold',
            fontSize: 16,
        },
        h4: {
            marginLeft: 4,
            marginTop: 1,
            marginBottom: 18,
            fontFamily: fontFamily,
            fontWeight: 'bold',
            fontSize: 14,
        },
        h5: {
            marginLeft: 4,
            marginTop: 1,
            marginBottom: 18,
            fontFamily: fontFamily,
            fontWeight: 'bold',
            fontSize: 13,
        },
        h6: {
            marginLeft: 4,
            marginTop: 1,
            marginBottom: 18,
            fontFamily: fontFamily,
            fontWeight: 'bold',
            fontSize: 12,
        },
        img: {

        },
        pre: {

        },
        evaluation: {
            marginBottom: 20,
            marginHorizontal: 40,
            color: '#000000',
            fontFamily: fontFamily,
            fontWeight: 'bold',
            fontSize: 35,
            textAlign: 'center'
        },
    })
}

/**
 * Get font name type
 * @param text
 */
export const getFontNames = (text: string) => {
    if (isJapanese(text)) {
        return [fontFamilyJapanese]
    } else if (isArabic(text)) {
        return [fontFamilyArabic]
    } else if (isChinese(text)) {
        return [fontFamilyChinese]
    } else if (isPortugueseOrFrench(text)) {
        return [fontFamilyPortugueseOrFrench]
    }
    return [fontFamilyOtherLanguages]
}

/**
 * Check if a text is Japanese
 * @param text
 */
export const isJapanese = (text: string) => /[\u3040-\u30ff\uff66-\uff9f]/.test(text)

/**
 * Check if a text is Arabic
 * @param text
 */
export const isArabic = (text: string) => /[\u0621-\u064A]/.test(text)

/**
 * Check if a text is chinese
 * @param text
 */
export const isChinese = (text: string) => /[\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff]/.test(text)

/**
 * Check if a text is Portuguese or French
 * @param text
 */
export const isPortugueseOrFrench = (text: string) => /[\u00B5-\u00FF]/.test(text)
