import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles( {
     selectAllLabel: {
         marginLeft: '5px',
         '& > *': {
             color: '#172B4D',
             fontSize: '14px',
             lineHeight: '20px',
             letterSpacing: 0,
             fontFamily: "SF Pro Text Regular",
             marginLeft: '-3px',
         }
     },

    listSelectorContainer: {
        marginTop: '36px'
    },
})


export { useStyles }
