import React from 'react'
import {Text, View} from '@react-pdf/renderer'
import {dateTimeTransformer} from '../../../../modules/utility/Transformers'
import {PDFExportPageActivityProperty} from '../../../../types/ActivityTypes'
import PDFRenderMarkdownContent from '../basic/PDFRenderMarkdownContent'

function PDFExportPageActivity(props: PDFExportPageActivityProperty) {
    const activity = props.singleActivity
    const date = dateTimeTransformer(activity.date)
    return <View style={props.style.marginHorizontalInPage}>
        <View style={props.style.bannerItemRow}>
            <Text style={props.style.activityCreator}>{activity.memberCreator?.fullName || '[deleted account]'}</Text>
            <Text style={props.style.activityDate}>{date}</Text>
        </View>
        <View style={props.style.activityText}>
            <PDFRenderMarkdownContent text={activity.data.text} style={props.style}/>
        </View>
    </View>
}

export default PDFExportPageActivity