import React from 'react'
import {PDFExportPageCustomFieldsProperty} from '../../../../types/PDFExportTypes'
import {Image, View} from '@react-pdf/renderer'
import PDFExportPageTitle from '../basic/PDFExportPageTitle'
import PDFExportPageCustomFieldItem from './PDFExportPageCustomFieldItem'

/**
 * Render a custom field with its name, value and the icon that shows the custom field type
 * @param props customField info and styles
 */
function PDFExportPageCustomFields(props: PDFExportPageCustomFieldsProperty) {
    const {style} = props
    return <React.Fragment>
        <View style={{...style.bannerItemRow, marginTop: 20}}>
            <View style={style.descriptionIcon}>
                <Image src="/icons/pdf-export/CustomFields.png"/>
            </View>
            <PDFExportPageTitle style={style} styleTitle={style.h2} title="Custom Fields:"/>
        </View>
        <View style={style.marginHorizontalInPage}>
            <View style={{display: 'flex'}}>
                <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                    {props.customFields.map(customField =>
                        <PDFExportPageCustomFieldItem key={customField.name} customField={customField}
                                                      style={style}/>
                    )}
                </View>
            </View>
        </View>
    </React.Fragment>
}

export default PDFExportPageCustomFields