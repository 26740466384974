import React from 'react'
import {Image, View} from '@react-pdf/renderer'
import {PDFExportPageDescriptionProperty} from '../../../../types/PDFExportTypes'
import PDFExportPageTitle from './PDFExportPageTitle'
import PDFRenderMarkdownContent from './PDFRenderMarkdownContent'

function PDFExportPageDescription(props: PDFExportPageDescriptionProperty) {
    return <React.Fragment>
        <View style={{...props.style.bannerItemRow, marginTop: 10}}>
            <View style={props.style.descriptionIcon}>
                <Image src="/icons/pdf-export/subjectIcon.png"/>
            </View>
            <PDFExportPageTitle
                style={props.style.h2}
                styleTitle={props.style.h2}
                title="Description:"/>
        </View>
        <View style={{...props.style.marginHorizontalInPage, ...props.style.description}}>
            <PDFRenderMarkdownContent {...props} text={props.description}/>
        </View>
    </React.Fragment>
}

export default PDFExportPageDescription