import {FilteredListCardData} from "../../types/FilteredListCardData";

export function doesCardContainChecklists(card: FilteredListCardData) {
    const searchResult = findObjectProperty(/Complete \(%\)/, card)

    if (searchResult === -1 || card.checklists === undefined || card.checklists.length === 0) {
        return false
    }

    return true
}

function findObjectProperty(pattern: RegExp, card: FilteredListCardData): number {
    return Object.keys(card).findIndex((key) => key.match(pattern))
}