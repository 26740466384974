import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles( {
    sectionMessageContainer: {
        marginTop: '36px',
        '& button': {
            padding: 0,
            margin: 0,
        }
    },
})


export { useStyles }
