import React from 'react'
import {Page} from '@react-pdf/renderer'
import {PDFExportPageProperty} from '../../../types/PDFExportTypes'
import PDFExportPageTitle from './basic/PDFExportPageTitle'
import PDFExportCardDetailsBanner from './basic/PDFExportCardDetailsBanner'
import PDFExportPageDescription from './basic/PDFExportPageDescription'
import {doesCardContainChecklists} from '../../../modules/checklist/ChecklistUtilities'
import PDFExportPageChecklists from './checklists/PDFExportPageChecklists'
import PDFExportPageActivities from './activities/PDFExportPageActivities'
import PDFExportPageCustomFields from './customfields/PDFExportPageCustomFields'
import PDFExportPageAttachments from './attachments/PDFExportPageAttachments'

function PDFExportPage(props: PDFExportPageProperty) {
    let pageElements = [
        <PDFExportPageTitle
            key="pageTitle"
            style={props.style}
            styleTitle={props.style.h1}
            title={props.card.name}
            text={props.card.archivedCard}/>,
        <PDFExportCardDetailsBanner
            key="detailsBanner"
            selectedFields={props.selectedFields}
            style={props.style}
            card={props.card}/>
    ]

    if (props.card.desc && props.selectedFields.includes('desc')) {
        pageElements.push(<PDFExportPageDescription
            key='cardDescription'
            style={props.style}
            description={props.card.desc}/>)
    }

    if (doesCardContainChecklists(props.card) && props.selectedFields.includes('checklists')) {
        pageElements.push(<PDFExportPageChecklists
            key='checklistCompletionPercentage'
            completionPercentage={props.card['Complete (%)']}
            checklists={props.card.checklists}
            style={props.style}
        />)
    }

    if (props.card.customFieldItems && props.card.customFieldItems.length > 0 && props.selectedFields.includes('customFieldItems')) {
        pageElements.push(<PDFExportPageCustomFields
            key='customFields'
            customFields={props.card.customFieldItems}
            style={props.style}
        />)
    }

    if (props.card.attachments && props.card.attachments.length > 0 && props.selectedFields.includes('attachments')) {
        pageElements.push(<PDFExportPageAttachments
            key='attachments'
            attachments={props.card.attachments}
            style={props.style}
        />)
    }

    if (props.card.comments && props.card.comments.length && props.selectedFields.includes('activity')) {
        pageElements.push(<PDFExportPageActivities
            key='cardActivity'
            style={props.style}
            activity={props.card.comments}/>)
    }

    return <Page size='A4' style={props.style.paddingVerticalInPage}>
        {pageElements}
    </Page>
}

export default PDFExportPage