import React from 'react'
import {ChecklistElementProperty} from '../../../../types/PDFExportTypes'
import {Image, Text, View} from '@react-pdf/renderer'
import moment from 'moment'

function ChecklistElement(props: ChecklistElementProperty) {

    const attributes = []

    if (props.completed) {
        attributes.push(
            <View key='CheckBoxIcon' style={props.style.checkBoxIcon}>
                <Image src='/icons/pdf-export/CheckBoxIcon.png'/>
            </View>
        )
    } else {
        attributes.push(
            <View key='CheckBoxOutlineBlankIcon' style={props.style.checkBoxIcon}>
                <Image src='/icons/pdf-export/CheckBoxOutlineBlankIcon.png'/>
            </View>
        )
    }

    attributes.push(<Text key='elementName'
                          style={{
                              ...props.style.checklistElementText,
                              width: 260
                          }}>{props.checklistElement.name}</Text>)
    if (props.checklistElement.member) {
        attributes.push(<Text key='elementMember'
                              style={{
                                  ...props.style.checklistElementText,
                                  width: 120
                              }}>{props.checklistElement.member}</Text>)
    }

    if (props.checklistElement.due) {
        const date = moment(props.checklistElement.due, 'DD-MM-YYYY')
        const rectColor = props.completed ? '#61BD50' : '#DF3D24'

        attributes.push(<View style={{...props.style.checklistElementDueDateRect, backgroundColor: rectColor}}
                              key='elementDueRect'>
                <Text key='elementDue'
                      style={props.style.checklistElementDueDateText}>{date.format('MMM D YYYY')}</Text>
            </View>
        )
    }

    return <View style={props.style.bannerItemRow}>
        {attributes}
    </View>
}

export default ChecklistElement