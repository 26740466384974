import React, { Component } from 'react';
import TrelloCardPreview from './TrelloCardPreview';

/**
 * Renders a 'preview' of a Trello List. The preview is styled the same way as a regular Trello list, and 
 * contains only the cards that are in the data provided.
 */
class TrelloListPreview extends Component {

    render() {
        const list = this.props.list;
        const cards = list.cards;

        let cardComponents = ''
        if (cards) {
            cardComponents = cards.map((card) =>
                <TrelloCardPreview
                    key={card.id}
                    cardName={card.name} />)
        }
        return (
            <div className="list">
                <header>{list.name}</header>
                <ul>{cardComponents}</ul>
                <footer>Add a card...</footer>
            </div>
        )
    }
}

export default TrelloListPreview;