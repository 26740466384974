import moment from 'moment'

/**
 * Decode the given url in a more readable string for the export.
 * @param {*} url The url we wish to format.
 * @returns the decoded url
 */
export function urlTransformer(url) {
    return decodeURIComponent(url)
}

export function urlExcelTransformer(url) {
    return {
        text: url,
        hyperlink: url,
        tooltip: url
    }
}

export function dateTimeTransformer(date) {
    return date ? new Date(date).toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hourCycle: 'h12' }) : ''
}

export function excelDateTimeTransformer(date) {
    return moment(date).format('YYYY-MM-DD HH:mm')
}

export function dateTransformer(date) {
    return date ? new Date(date).toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric' }) : ''
}

export function excelDueTransformer(date) {
    return date ? excelDateTimeTransformer(date) : ''
}

export function dueTransformer(date) {
    if(!date) return ''
    const isOverdue = new Date(date).getTime() < new Date().getTime()
    return {
        richText: [
            {
                text: dateTimeTransformer(date) + (isOverdue ? ',\n' : '')
            },
            {
                text: isOverdue ? 'Overdue' : '',
                font: {
                    color: {'argb': 'FC902E'},
                    bold: true,
                    name: 'SF Pro Text Regular',
                    size: 14
                }
            }
        ]
    }
}

function _getTitleSize(descriptionPartUpdated) {
    let titleSize = 0
    let endFound = false
    descriptionPartUpdated.split('').forEach(descriptionChar => {
        if (!endFound && descriptionChar === '#') titleSize++
        else endFound = true
    })
    return titleSize > 6 ? 6 : titleSize
}

export function csvDescriptionTransformer(description) {
    return description.replaceAll('\\_', '_')
}

export function descriptionTransformer(description) {

    const boldRegex = /((?![a-zA-Z0-9])\*\*.*\*\*(?![a-zA-Z0-9]))/gm
    const italicRegex = /((?![a-zA-Z0-9])_.*_(?![a-zA-Z0-9]))/gm
    // eslint-disable-next-line
    const italic2Regex = /((?![a-zA-Z0-9\*])\*.*\*(?![a-zA-Z0-9\*]))/gm
    const titleRegex = /(^#{1,6}.*(\n|$))/gm
    const title1Regex = /(.*\n===)/gm
    const title2Regex = /(.*\n---)/gm
    const strikethroughRegex = /((?![a-zA-Z0-9])~~.*~~(?![a-zA-Z0-9]))/gm
    const composedRegex = new RegExp([boldRegex, italicRegex, italic2Regex, titleRegex, title1Regex, title2Regex, strikethroughRegex].map(regex => regex.source).join('|'), 'gm')

    return {
        richText: description.split(composedRegex)
            .filter(descriptionPart => descriptionPart && descriptionPart.length > 0)
            .map(descriptionPart => {
                const isBold = descriptionPart.startsWith('**') && descriptionPart.endsWith('**')
                const isItalic = (descriptionPart.startsWith('_') && descriptionPart.endsWith('_')) || (!isBold && descriptionPart.length > 1 && descriptionPart.startsWith('*') && descriptionPart.endsWith('*'))
                const isStrike = descriptionPart.startsWith('~~') && descriptionPart.endsWith('~~')
                const isTitle = descriptionPart.startsWith('#') && (descriptionPart.endsWith('#') || descriptionPart.endsWith('\n'))
                const isHeader = descriptionPart.endsWith('\n===') || descriptionPart.endsWith('\n---')
                let titleSize = 0
                if(isTitle) titleSize = _getTitleSize(descriptionPart)
                else if(isHeader) titleSize = descriptionPart.endsWith('\n---') ? 3 : 1

                let descriptionPartUpdated = descriptionPart
                if(isBold || isStrike) descriptionPartUpdated = descriptionPartUpdated.slice(2, -2)
                if(isItalic) descriptionPartUpdated = descriptionPartUpdated.slice(1, -1)
                if(isTitle) {
                    if(descriptionPartUpdated.endsWith('#'.repeat(titleSize)))
                        descriptionPartUpdated = descriptionPartUpdated.slice(titleSize, -titleSize)
                    else if (descriptionPartUpdated.endsWith('#'.repeat(titleSize) + '\n'))
                        descriptionPartUpdated = descriptionPartUpdated.slice(titleSize, -titleSize - 1) + '\n'
                    else
                        descriptionPartUpdated = descriptionPartUpdated.slice(titleSize)
                }

                if(isHeader) descriptionPartUpdated = descriptionPartUpdated.slice(0,-4) + '\n'
                descriptionPartUpdated = descriptionPartUpdated.replaceAll('\\_', '_')

                return {
                    text: descriptionPartUpdated,
                    font: {
                        name: 'SF Pro Text Regular',
                        size: isTitle || isHeader ? (23 - titleSize) : 14,
                        bold: isBold || isTitle || isHeader,
                        strike: isStrike,
                        italic: isItalic
                    }
                }
        })
    }
}

export function attachmentsTransformer(attachments) {
    if(!attachments || attachments.length === 0) return ''

    if (attachments.length > 1) {
        return attachments.map((attachment) => `${attachment.name} : ${attachment.url}`).join('\n')
    } else {
        const attachmentUrl = attachments[0].url
        return {
            text: attachments[0].name,
            hyperlink: attachmentUrl,
            tooltip: attachmentUrl.length > 150 ? `${attachments[0].url.slice(0,147)}...` : attachmentUrl
        }
    }
}

/**
 * Transforms the object containing the members into a String that can be rendered in an 'export'.
 * @param {*} members This will come in as a list of objects (member).
 * @returns The 'modified' output that contains the text we wish to display.
 */
export function membersTransformer(members) {
    if(members && members.length > 0)
        return members.filter(member => member).length + ',\n' +
            members.map(member => member ? member.fullName : '')
            .filter(memberName => memberName !== '')
            .join(',\n')
    else
        return '0'
}

/**
 * Generate the activity text based on the activity type
 * @param activity specific action performed by a user on a trello card
 * @param boards{Array<any>} list of all boards
 * @return {string} Activity text to export
 */
export function generateActivityText(activity, boards = []) {
    switch (activity.type) {
        case 'createCard':
            return `Added this card to ${activity.data.list.name}`
        case 'addAttachmentToCard':
            return `Attached ${activity.data.attachment.name} (${activity.data.attachment.url}) to this card`
        case 'addChecklistToCard':
            return `Added ${activity.data.checklist.name} to this card`
        case 'addMemberToCard':
            if(activity.member && activity.member.id === activity.idMemberCreator) return 'Joined this card'
            else return `Added ${activity.member && (activity.member.fullName || activity.member.username) ? `${activity.member.fullName ? activity.member.fullName + ' ' : ''}${activity.member.username ? `(${activity.member.username}) ` : ''}` : '[deleted account] '}to this card`
        case 'convertToCardFromCheckItem':
            return `Converted ${activity.data.card.name} from a checklist item on this card`
        case 'copyCard':
            return `Copied this card from ${activity.data.cardSource.name} in list ${activity.data.list.name}`
        case 'deleteAttachmentFromCard':
            return `Deleted the ${activity.data.attachment.name} attachment from this card`
        case 'moveCardFromBoard':
            const boardTarget = boards.find(board => board.id === activity.data.boardTarget.id)
            if(boardTarget) return `Transferred this card from ${boardTarget.name}`
            else return 'Transferred this card from another board'
        case 'moveCardToBoard':
            const boardSource = boards.find(board => board.id === activity.data.boardSource.id)
            if(boardSource) return `Transferred this card to ${boardSource.name}`
            else return 'Transferred this card to another board'
        case 'removeChecklistFromCard':
            return `Removed ${activity.data.checklist.name} from this card`
        case 'removeMemberFromCard':
            if(activity.member?.id && (activity.data.member?.id === activity.member.id || activity.data.idMember === activity.member.id)) return 'Left this card'
            else return `Removed ${activity.data.member && (activity.data.member.fullName || activity.data.member.username) ? `${activity.data.member.fullName ? activity.data.member.fullName + ' ' : ''}${activity.data.member.username ? `(${activity.data.member.username}) ` : ''}` : '[deleted account] '}from this card`
        case 'updateCard':
            return generateUpdateCardText(activity)
        case 'updateCheckItemStateOnCard':
            if(activity.data.checkItem.state === 'complete') return `Completed item ${activity.data.checkItem.name} on this card`
            else return `Uncompleted item ${activity.data.checkItem.name} on this card`
        case 'updateCustomFieldItem':
            return `Updated the value for the ${activity.data.customField.name} custom field on this card`
        default:
            return ''
    }
}

/**
 * Generate the action text for update card actions
 * @param activity specific action performed by a user on a trello card
 * @return {string} generated update card action
 */
export function generateUpdateCardText(activity) {
    if(activity.data?.card?.due && !activity.data.old?.due){
        return `Set this card to be due ${dateTimeTransformer(activity.data.card.due)}`
    } else if(activity.data?.card?.due && activity.data.old.due) {
        return `Changed the due date of this card to ${dateTimeTransformer(activity.data.card.due)}`
    }  else if(!activity.data?.card?.due && activity.data?.old?.due) {
        return 'Removed the due date from this card'
    } else if(activity.data?.listBefore && activity.data.listAfter) {
        return `Moved this card from ${activity.data.listBefore.name} to ${activity.data.listAfter.name}`
    } else return ''
}

/**
 * Transforms the object containing the labels into a String that can be rendered in an 'export'.
 * @param {*} labels The labels as an 'object' that we want to convert.
 * @returns The 'modified' output that contains the text we wish to display.
 */
export function labelsWithColorTransformer(labels) {
    return {
        richText: labels.map(label => ({
            text: (label.name || label.color) + '\n',
            font: {
                name: 'SF Pro Text Regular',
                size: 14,
                color: {'argb': label.color ? LabelColors.find(labelColor => labelColor.color === label.color)?.argbColor || 'FFFFFF' : 'B3BAC5'},
                bold: true
            }
        }))
    }
}

export const LabelColors = [
    {argbColor: 'B7DDB0', color: 'green_light'},
    {argbColor: '7BC86C', color: 'green'},
    {argbColor: '7BC86C', color: 'green_dark'},
    {argbColor: 'F5EA92', color: 'yellow_light'},
    {argbColor: 'F5DD29', color: 'yellow'},
    {argbColor: 'E6C60D', color: 'yellow_dark'},
    {argbColor: 'FAD29C', color: 'orange_light'},
    {argbColor: 'FFAF3F', color: 'orange'},
    {argbColor: 'E79217', color: 'orange_dark'},
    {argbColor: 'EFB3AB', color: 'red_light'},
    {argbColor: 'EF7564', color: 'red'},
    {argbColor: 'CF513D', color: 'red_dark'},
    {argbColor: 'DFC0EB', color: 'purple_light'},
    {argbColor: 'CD8DE5', color: 'purple'},
    {argbColor: 'A86CC1', color: 'purple_dark'},
    {argbColor: '8BBDD9', color: 'blue_light'},
    {argbColor: '5BA4CF', color: 'blue'},
    {argbColor: '026AA7', color: 'blue_dark'},
    {argbColor: '8FDFEB', color: 'sky_light'},
    {argbColor: '29CCE5', color: 'sky'},
    {argbColor: '00AECC', color: 'sky_dark'},
    {argbColor: 'B3F1D0', color: 'lime_light'},
    {argbColor: '6DECA9', color: 'lime'},
    {argbColor: '4ED583', color: 'lime_dark'},
    {argbColor: 'F9C2E4', color: 'pink_light'},
    {argbColor: 'FF8ED4', color: 'pink'},
    {argbColor: 'E568AF', color: 'pink_dark'},
    {argbColor: '505F79', color: 'black_light'},
    {argbColor: '344563', color: 'black'},
    {argbColor: '091E42', color: 'black_dark'},
]
