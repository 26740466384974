import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles( {
    container: {
        backgroundColor: 'white',
        minWidth: '500px'
    }
  }
)


export { useStyles }
