import React from 'react'
import {PDFExportPageAttachmentProperty} from '../../../../types/PDFExportTypes'
import {Image, Link, Text, View} from '@react-pdf/renderer'
import PDFExportPageTitle from '../basic/PDFExportPageTitle'
import {dateTimeTransformer} from '../../../../modules/utility/Transformers'

function PDFExportPageAttachments(props: PDFExportPageAttachmentProperty) {
    const {style} = props

    return <View>
        <View style={{...style.bannerItemRow, marginTop: 10}}>
            <View style={style.descriptionIcon}>
                <Image src="/icons/pdf-export/attachment.png"/>
            </View>
            <PDFExportPageTitle style={style} styleTitle={props.style.h2} title="Attachments:"/>
        </View>
        <View style={style.marginHorizontalInPage}>
            <View style={style.attachmentsContainer}>
                {props.attachments.map((attachment, index) =>
                    (<View key={attachment.id}>
                            <View style={style.attachmentContainer}>
                                <View style={style.attachmentImageContainer}>
                                    <Link src={attachment.url}>
                                        <Image style={style.attachmentImage} src={'/icons/pdf-export/attachment-big.png'}/>
                                    </Link>
                                </View>
                                <View style={style.attachmentInfoContainer}>
                                    <View style={style.attachmentNameContainer}>
                                        <View style={style.attachmentSpaceNameContainer}></View>
                                        <View>
                                            <Text style={style.attachmentNameText}>
                                                <Link src={attachment.url}>
                                                    {attachment.name}
                                                </Link>
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={style.attachmentDateContainer}>
                                        <Text style={style.attachmentDateText}>
                                            <Link src={attachment.url}>
                                                Added {dateTimeTransformer(attachment.date)}
                                            </Link>
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={style.attachmentBoxToFormat}></View>
                        </View>
                    )
                )}
            </View>
        </View>
    </View>
}

export default PDFExportPageAttachments