import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
        contentContainer: {
            padding: '31px 48px 31px 48px'
        },
        appTitle: {
            color: '#172B4E',
            fontSize: 24,
            fontWeight: 'bold',
            letterSpacing: 0,
            lineHeight: '29px',
            marginBottom: '24px'
        },
        text1: {
            color: '#172B4E',
            fontWeight: 'bold',
            fontSize: 18,
            letterSpacing: 0,
            lineHeight: '29px',
            marginBottom: 12
        },
        text2: {
            color: '#172B4D',
            fontSize: 14,
            letterSpacing: 0,
            lineHeight: '21px',
            marginTop: '12px',
            marginBottom: '12px'
        },
        toggleDiv: {
            marginBottom: '24px'
        }
    }
)

export { useStyles }
