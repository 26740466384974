import React from 'react'
import {Text, View} from '@react-pdf/renderer'
import {PDFExportPageTitleProperty} from '../../../../types/PDFExportTypes'
import {splitTextByLimit} from '../PDFExportUtils'

function PDFExportPageTitle(props: PDFExportPageTitleProperty) {

    const titleFormatted = splitTextByLimit(props.title || '', 35)

    if (titleFormatted && props.text) {
        return (<>
            <View style={props.style.pageSubtitleView} key="pageTopViewSubtitle">
                <Text style={props.style.pageTitleSubtitle}>{props.text}</Text>
            </View>
            <View style={props.style.topPageView} key="pageTopViewTitle">
                <Text style={props.styleTitle}>{titleFormatted}</Text>
            </View>
        </>)
    }
    return <Text style={props.styleTitle}>{titleFormatted}</Text>
}

export default PDFExportPageTitle