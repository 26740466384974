import TrelloUrlBuilder from 'trello-shared-resources/dist/modules/url/TrelloUrlBuilder'
import TrelloEntityType from 'trello-shared-resources/dist/modules/url/TrelloEntityType'
import {getToken} from 'trello-shared-resources/dist/services/TokenService'
import {FetchDataError, MessageType} from './error/FetchDataError'
import * as Sentry from '@sentry/browser'

const supportPortalLink = (`<a href="https://productsupport.adaptavist.com/servicedesk/customer/portal/51" target="_blank">support portal</a>`)

const TRELLO_BASE_URL = 'https://api.trello.com/1'

/**
 * Functions for retrieving required data from Trello
 */
async function getMembershipsForBoard(licenseDetails, boardId, token) {
    const key = licenseDetails.apiKey
    return get(getBoardMembershipsUrl(key, token, boardId))
}

function getBoardMembershipsUrl(key, token, boardId) {
    return new TrelloUrlBuilder()
        // Authentication
        .withKey(key)
        .withToken(token)

        // Entity Type Request
        .withEntityType(TrelloEntityType.Boards)
        .withEntityId(boardId)
        .withEntityAction('memberships')

        // Search parameters
        .addQueryParameter('member', 'true')

        .build()
}

/**
 * Find cards checklists through trello API
 * @param cardsOriginal{Array} cards list
 * @param trelloAppKey{string} appKey to be able to make request to Trello API
 * @param token{string} trello app token to auth api call
 * @return array of checklists
 */
export const getChecklistsByCards = async (cardsOriginal, trelloAppKey, token) => {
    try {
        return await getChecklistsByCardsPaginated(cardsOriginal, trelloAppKey, token)
    } catch (error) {
        console.log(`Error finding checklists by cards: ${cardsOriginal}`, error)
        return []
    }
}

/**
 * Find checklists by card to be called recursively
 * @param cardsOriginal{Array} cards list
 * @param trelloAppKey{string} appKey to be able to make request to Trello API
 * @param token{string} trello app token to auth api call
 * @return {Promise<Array>} actions array found or empty array otherwise
 */
async function getChecklistsByCardsPaginated(cardsOriginal, trelloAppKey, token) {
    // collecting promises to run in parallel
    const chunked10Cards = chunkArray(cardsOriginal, 10)
    const promises = []
    for (const cards of chunked10Cards) {
        const batchCardsURLs = cards.map(card => `/1/cards/${card.id}/checklists`).join(',')
        const BATCH_CARDS_ACTIVITY_URL = `${TRELLO_BASE_URL}/batch?key=${trelloAppKey}&token=${token}&urls=${batchCardsURLs}`
        promises.push(get(BATCH_CARDS_ACTIVITY_URL))
    }

    const responses = await Promise.all(promises)
    const checklists = responses.map(response => response.map(cardDataResponse => {
        return cardDataResponse[200] ? cardDataResponse[200] : []
    }).flat()).flat()
    return checklists
}

/**
 * Get boards visible for the current member given
 * @param licenseDetails object to get current memberId and trello app key
 * @param token{string} trello app token to auth api call
 * @return {Promise<any|undefined>} a promise that will return a list with boards
 */
function getMemberBoards(licenseDetails, token) {
    const key = licenseDetails.apiKey
    const memberId = licenseDetails.trelloIframeContext.getContext().member
    return get(getMemberBoardsUrl(key, token, memberId))
}

/**
 * Generate the URL to get boards visible for the member given by it ID
 * @param key{string} trello app key to auth api call
 * @param token{string} trello app token to auth api call
 * @param memberId{string} member ID to get their visible boards
 * @return {string} generated URL
 */
function getMemberBoardsUrl(key, token, memberId) {
    return new TrelloUrlBuilder()
        // Authentication
        .withKey(key)
        .withToken(token)

        // Entity Type Request
        .withEntityType(TrelloEntityType.Members)
        .withEntityId(memberId)
        .withEntityAction('boards')
        .build()
}

/**
 * Generate the URL to get archived cards in a board
 * @param key{string} trello app key to auth api call
 * @param token{string} trello app token to auth api call
 * @param boardId{string} member ID to get their visible boards
 * @param previousArchivedCardTimestamp{number} previous archived card timestamp, to get paginated results
 * @return {string} generated URL
 */
const getArchivedCardsUrl = (key, token, boardId, previousArchivedCardTimestamp = '') => {
    const archivedCardsTrelloUrl = new TrelloUrlBuilder()
        // Authentication
        .withKey(key)
        .withToken(token)

        // Entity Type Request
        .withEntityType(TrelloEntityType.Boards)
        .withEntityId(boardId)
        .withEntityAction('cards/closed')

        .addQueryParameter('customFieldItems', 'true')
        .addQueryParameter('attachments', 'true')
        .addQueryParameter('limit', 1000)

    if (previousArchivedCardTimestamp !== '') archivedCardsTrelloUrl.addQueryParameter('before', previousArchivedCardTimestamp)

    return archivedCardsTrelloUrl.build()
}

/**
 * Find archived cards that belongs to the board given its ID
 * @param licenseDetails object to get current memberId and trello app key
 * @return a list of objects with card info
 */
export const findBoardArchivedListsCards = async (licenseDetails) => {
    const trelloContext = licenseDetails.trelloIframeContext
    const boardId = trelloContext.getContext().board
    const trelloAppKey = licenseDetails.apiKey
    const token = await getToken()

    try {
        const BOARD_CARDS_URL = `${TRELLO_BASE_URL}/boards/${boardId}/lists?key=${trelloAppKey}&token=${token}&filter=closed`
        const response = await fetch(BOARD_CARDS_URL, {method: 'GET'})
        const archivedLists = await response.json()
        const cardLists = await _findCardsFromArchivedLists(archivedLists, token, licenseDetails)
        return archivedLists.map(list => {
            const archivedCards = cardLists.filter(card => card.idList === list.id)
            return {
                id: list.id,
                name: list.name.concat(' (Archived)'),
                cards: archivedCards
            }
        })

    } catch (error) {
        console.log(`Error finding archived lists cards in board: ${boardId}`, error)
        return []
    }
}

/**
 * Generate Trello type batch request
 * @param trelloAppKey trello app key
 * @param trelloAppToken token to be able to make request to Trello API
 * @param urlType url from trello collections to generate
 * @return string batch request generated
 */
const _getBatchRequestType = (trelloAppKey, trelloAppToken, urlType) => {
    return `${TRELLO_BASE_URL}/batch?key=${trelloAppKey}&token=${trelloAppToken}&urls=${urlType}`
}

/**
 * Find all cards from the given list of archived lists
 * @param archivedLists an array of objects that contains archived list info
 * @param trelloAppToken token to be able to make request to Trello API
 * @param licenseDetails object to get current memberId and trello app key
 * @return a list of objects with card info
 */
const _findCardsFromArchivedLists = async (archivedLists, trelloAppToken, licenseDetails) => {
    const trelloAppKey = licenseDetails.apiKey
    const chunkedArchivedLists = chunkArray(archivedLists, 10)
    const archivedListsCards = []
    for (const archivedListChunk of chunkedArchivedLists) {
        const batchListURLs = archivedListChunk
            .filter(list => list.id)
            .map(list => `/lists/${list.id}/cards?pluginData=true`)
            .join(',')
        const BATCH_LISTS_URL = _getBatchRequestType(trelloAppKey, trelloAppToken, batchListURLs)
        const response = await fetch(BATCH_LISTS_URL, {method: 'GET'})
        if (response.status === 200) {
            const archivedListCardsResponse = await response.json()
            const cards = archivedListCardsResponse.map((cardsResponse) => {
                if (cardsResponse['200']) {
                    return cardsResponse['200']
                } else return undefined
            }).filter((member) => member)
            archivedListsCards.push(...cards)
        }
    }
    return archivedListsCards.reduce((acc, curr) => acc.concat(curr), [])
}

/**
 * Returns archived cards in a board. Members details are also associated with the gathered cards
 *
 * @param licenseDetails
 * @returns {Promise<any>}
 */
export async function getArchivedCards(licenseDetails) {
    const trelloContext = licenseDetails.trelloIframeContext
    const board = trelloContext.getContext().board
    const trelloAppKey = licenseDetails.apiKey
    const token = await getToken()

    const archivedCards = await _findArchivedCardsPaginated(trelloAppKey, token, board)

    // for each archived card we need to get more details
    const memberIds = [...new Set(archivedCards.map(card => card.idMembers).flat())]
    const chunked10Members = chunkArray(memberIds, 10)
    const members = []
    for (const arrayOfMemberIds of chunked10Members) {
        const batchMembersURLs = arrayOfMemberIds.map(memberId => `/members/${memberId}`).join(',')
        const BATCH_MEMBERS_URL = _getBatchRequestType(trelloAppKey, token, batchMembersURLs)
        const responses = await get(BATCH_MEMBERS_URL)
        responses.forEach((singleResponse) => {
            if (singleResponse['200']) {
                members.push(singleResponse['200'])
            }
        })
    }

    for (const card of archivedCards) {
        card.members = card.idMembers.map(memberId => {
                return members.find(member => member.id === memberId)
            }
        ).filter(member => member)
    }
    return archivedCards
}

/**
 * Get all the archived cards on a board by chunks of 1000
 * @param trelloAppKey{string} trello app key to auth api call
 * @param token{string} trello app token to auth api call
 * @param board{string} board ID to get the archived cards
 * @param previousArchivedCardId{string} previous archived card id, to get paginated results
 */
async function _findArchivedCardsPaginated(trelloAppKey, token, board) {
    const archivedCards = await get(getArchivedCardsUrl(trelloAppKey, token, board))
    let lastArchivedCards = archivedCards

    while (lastArchivedCards.length === 1000) {
        const timestampDate = Date.parse(lastArchivedCards[999].dateLastActivity)
        lastArchivedCards = await get(getArchivedCardsUrl(trelloAppKey, token, board, timestampDate))
        archivedCards.push(...lastArchivedCards)
    }
    return archivedCards
}

/**
 * Perform a get request. If it respond with a 429 error, it will try again in 2 seconds, then in 4, 6, 8...
 * @param url endpoint to call
 * @param retry number of retry
 * @return {Promise<any|undefined>} a promise that will return the response content
 */
async function get(url, retry = 1) {
    let response = await fetch(url, {method: 'GET'})
    if (response.ok) {
        return await response.json()
    } else {
        const isBatchRequest = url.startsWith(`${TRELLO_BASE_URL}/batch`)
        const responseBody = await response.json()
        if (response.status === 429 || (response.status === 200 && isBatchRequest && responseBody.some(response => response.statusCode && response.statusCode === 429))) {
            await new Promise(resolve => setTimeout(resolve, (retry * 2) * 1000))
            return await get(url, ++retry)
        } else {
            const message = await response.text()
            throw new FetchDataError(`Trello hiccupped.`,
                ` Hit us up on our ${supportPortalLink} so we can take a closer look.<p>Status: ${response.status}. Message: ${message}</p>`,
                MessageType.warning)
        }
    }
}

/**
 * If no cards are present in the board, we show an info message to inform the user
 *
 * @param cards
 */
const checkCardsInBoard = (cards) => {
    if (!cards.length) {
        throw new FetchDataError('Whoops!', 'Looks like there are no cards on the board yet.', MessageType.info)
    }
}


/**
 * Initiates loading of required data from the Trello Client Library and returns a group Promise
 * that will indicate when all data has loaded.
 */
export async function loadTrelloData(licenseDetails) {
    try {
        const trelloContext = licenseDetails.trelloIframeContext
        const token = await getToken()

        const board = await trelloContext.board('all')
        const lists = await trelloContext.lists('all')

        const cards = lists.map(l => l.cards).flat()
        checkCardsInBoard(cards)

        const members = await getMembershipsForBoard(licenseDetails, board.id, token)
        const boards = await getMemberBoards(licenseDetails, token)

        return {
            trelloData: {
                board: board,
                lists: lists,
                members: members,
                apiKey: licenseDetails.apiKey,
                token: token,
                boards: boards
            }
        }
    } catch (error) {
        let errorToReport = error

        // handling severe network errors we couldn't handle in the previous calls (e.g. TypeError or Error types)
        if (!(error instanceof FetchDataError)) {
            errorToReport = new FetchDataError(`Trello hiccupped.`,
                `Hit us up on our ${supportPortalLink} so we can take a closer look.<p>Error: ${error.name}. Message: ${error.message}</p>`,
                MessageType.warning)
        }

        // reporting to Sentry not expected errors
        if (errorToReport instanceof FetchDataError && errorToReport.messageType === MessageType.warning) {
            Sentry.captureException(errorToReport)
        }

        return {
            messageToShow: errorToReport
        }
    }
}

/**
 * Split the original array in parts of chunkSize length and return an array of arrays
 * @param myArray array values to split
 * @param chunkSize end position to splitgit
 * @return array elements
 */
const chunkArray = (myArray, chunkSize) => {
    const clonedArray = [...myArray]
    const chunckedArray = []
    while (clonedArray.length) {
        chunckedArray.push(clonedArray.splice(0, chunkSize))
    }
    return chunckedArray
}

/**
 * Find actions by card to be called recursively
 * @param cardsOriginal{Array} object list with id to find
 * @param trelloAppKey{string} appKey to be able to make request to Trello API
 * @param token{string} trello app token to auth api call
 * @return {Promise<Array>} actions array found or empty array otherwise
 */
async function findActionsByCardsPaginated(cardsOriginal, trelloAppKey, token) {
    const chunked10Cards = chunkArray(cardsOriginal, 10)
    let actionsByCard = {}

    // collecting promises to run in parallel
    const promises = []
    for (const cards of chunked10Cards) {
        const batchCardsURLs = cards.map(card => `/1/card/${card.id}/actions?filter=all%26limit=1000${card.before ? `%26before=${card.before}` : ''}`).join(',')
        const BATCH_CARDS_ACTIVITY_URL = `${TRELLO_BASE_URL}/batch?key=${trelloAppKey}&token=${token}&urls=${batchCardsURLs}`
        promises.push(get(BATCH_CARDS_ACTIVITY_URL))
    }

    const cardsWithMoreActions = []
    // parsing API requests
    const responses = await Promise.all(promises)
    for (let index = 0; index < chunked10Cards.length; index++) {
        const cards = chunked10Cards[index]
        responses[index].forEach((cardResponse, index) => {
            const cardActions = cardResponse['200']
            if (cardActions) {
                actionsByCard[cards[index].id] = cardActions
                if (cardActions.length === 1000) {
                    cardsWithMoreActions.push({id: cards[index].id, before: cardActions[999].id})
                }
            }
        })
    }

    if (cardsWithMoreActions.length > 0) {
        const actions1000 = await findActionsByCardsPaginated(cardsWithMoreActions, trelloAppKey, token)
        for (const cardKey of Object.keys(actions1000)) {
            actionsByCard[cardKey] = [...actionsByCard[cardKey], ...actions1000[cardKey]]
        }
    }

    return actionsByCard
}

/**
 * Find cards actions through trello API
 * @param cardsOriginal object list with id to find
 * @param trelloAppKey appKey to be able to make request to Trello API
 * @return array of cards with actions
 */
export const findActionsByCards = async (cardsOriginal, trelloAppKey) => {
    try {
        const token = await getToken()
        return await findActionsByCardsPaginated(cardsOriginal, trelloAppKey, token)
    } catch (error) {
        console.log(`Error finding cards activity by cards: ${cardsOriginal}`, error)
        return []
    }
}

const USER_DISMISS_MESSAGE_KEY = 'userDismissMessage'

/**
 * Store the current user on the member's plugin data to know that this user have dismissed the message
 * @param licenseDetails data needed to store on the trello API
 */
export const storeUserDismissMessageNewFeature = async (licenseDetails) => {
    const trelloContext = licenseDetails.trelloIframeContext

    const memberPluginData = await getMemberPluginData(trelloContext, USER_DISMISS_MESSAGE_KEY)
    if (memberPluginData && memberPluginData !== '' && !memberPluginData.userDismissMessage) {
        memberPluginData.userDismissMessage = true
        trelloContext.set('member', 'private', USER_DISMISS_MESSAGE_KEY, memberPluginData)
    } else if (!memberPluginData || memberPluginData === '') {
        trelloContext.set('member', 'private', USER_DISMISS_MESSAGE_KEY, {userDismissMessage: true})
    }
}

/**
 * Check if the current user has dismissed the message
 * @param licenseDetails data needed to store on the trello API
 * @return {Promise<boolean>} true if the user has dismissed the message or false otherwise
 */
export const hasUserDismissMessageNewFeature = async (licenseDetails) => {
    const trelloContext = licenseDetails.trelloIframeContext
    const memberPluginData = await getMemberPluginData(trelloContext, USER_DISMISS_MESSAGE_KEY)
    return memberPluginData && memberPluginData !== '' && memberPluginData.userDismissMessage
}

const USER_DISMISS_CARD_DATE_CREATION_KEY = 'userDismissCardDateCreationMessage'

/**
 * Store the current user on the member's plugin data to know that this user have dismissed the card date creation message
 * @param licenseDetails data needed to store on the trello API
 */
export const storeUserDismissCardDateCreationMessage = async (licenseDetails) => {
    const trelloContext = licenseDetails.trelloIframeContext

    const memberPluginData = await getMemberPluginData(trelloContext, USER_DISMISS_CARD_DATE_CREATION_KEY)
    if (memberPluginData && memberPluginData !== '' && !memberPluginData.userDismissCardDateCreationMessage) {
        memberPluginData.userDismissCardDateCreationMessage = true
        trelloContext.set('member', 'private', USER_DISMISS_CARD_DATE_CREATION_KEY, memberPluginData)
    } else if (!memberPluginData || memberPluginData === '') {
        trelloContext.set('member', 'private', USER_DISMISS_CARD_DATE_CREATION_KEY, {userDismissCardDateCreationMessage: true})
    }
}

/**
 * Check if the current user has dismissed the card date creation message
 * @param licenseDetails data needed to store on the trello API
 * @return {Promise<boolean>} true if the user has dismissed the message or false otherwise
 */
export const hasUserDismissCardDateCreationMessage = async (licenseDetails) => {
    const trelloContext = licenseDetails.trelloIframeContext
    const memberPluginData = await getMemberPluginData(trelloContext, USER_DISMISS_CARD_DATE_CREATION_KEY)
    return memberPluginData && memberPluginData !== '' && memberPluginData.userDismissCardDateCreationMessage
}


/**
 * Get member plugin data
 * @param trelloContext object that contains method and info to get member plugin data
 * @param key string key to get the data
 * @return {Promise<string | any>}
 */
async function getMemberPluginData(trelloContext, key) {
    return await trelloContext.get('member', 'private', key, '')
}