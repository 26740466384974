import SectionMessage from '@atlaskit/section-message'
import React, {useEffect, useState} from 'react'
import {Box} from '@material-ui/core'
import {useStyles} from './EvaluationLicenseSectionMessageStyles'
import {MenuEnum} from 'trello-shared-resources/dist/components/menu/MenuEnum'
import Button from '@atlaskit/button'
import {TrackActionEvent} from 'trello-shared-resources/dist'

/**
 * Render a message explaining that the user is on evaluation license
 */
export const EvaluationLicenseSectionMessage = (props: { licenseDetails: any, setSelectedMenuItem: (value: string) => void }) => {

    const classes = useStyles()

    const [user, setUser] = useState()

    useEffect(() => {
        props.licenseDetails.auth0Client.getUser().then((user: any) => setUser(user))
    }, [props.licenseDetails.auth0Client])

    const buyNowHandler = () => {
        const trelloContextInfo = props.licenseDetails.trelloIframeContext.getContext()
        TrackActionEvent('Buy Now', trelloContextInfo, {
            board_id: trelloContextInfo.board
        })
        if (user) props.setSelectedMenuItem(MenuEnum.APP_SETTINGS_BILLING_TAB)
        else props.setSelectedMenuItem(MenuEnum.APP_SETTINGS)
    }

    return <Box className={classes.sectionMessageContainer}>
        <SectionMessage
            title="You are on a 14-day free trial"
            appearance="warning"
        >
            <div>
                Free trials are restricted to a limited number of cards per export. To take full advantage of the app,
                please purchase a license.
            </div>
            <Button appearance="link" onClick={buyNowHandler}>Buy Now</Button>
        </SectionMessage>
    </Box>
}