import React, {useState} from 'react'
import {LicenseDetailsContext} from 'trello-shared-resources/dist'
import {Grid} from '@material-ui/core'
import BoardExportContent from '../boardExport/BoardExportContent'
import {useStyles} from './BoardExportDialogStyles'
import Customisation from '../customisation/Customisation'
import {MenuEnum} from './MenuEnum'
import {ButtonItem} from '@atlaskit/side-navigation'
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line'
import PreferencesIcon from '@atlaskit/icon/glyph/preferences'
import {getMenuContent} from 'trello-shared-resources/dist/services/MenuService'
import LeftBar from 'trello-shared-resources/dist/components/leftBar/LeftBar'

const BoardExportDialog = (props) => {
    const classes = useStyles()
    const [selectedMenuItem, setSelectedMenuItem] = useState(props.selectedMenuItem || MenuEnum.EXPORT)

    const menuButtons = [
        <ButtonItem isSelected={selectedMenuItem === MenuEnum.EXPORT}
                    iconBefore={<GraphLineIcon label="Export" primaryColor={'#0052CC'}/>}
                    key={MenuEnum.EXPORT}
                    onClick={() => {
                        setSelectedMenuItem(MenuEnum.EXPORT)
                    }}>
            Export
        </ButtonItem>,
        <ButtonItem iconBefore={<PreferencesIcon label="Customisation" primaryColor={'#42526E'}
                                                 size="medium"/>}
                    isSelected={selectedMenuItem === MenuEnum.CUSTOMISATION}
                    key={MenuEnum.CUSTOMISATION}
                    onClick={() => {
                        setSelectedMenuItem(MenuEnum.CUSTOMISATION)
                    }}>
            Customisation
        </ButtonItem>
    ]

    return (
        <LicenseDetailsContext.Consumer>
            {licenseDetails => {
                if (licenseDetails.isLoading) {
                    return (<div>Loading...</div>)
                }

                if (!licenseDetails.trelloIframeContext) {
                    return (
                        <React.Fragment>
                            <p>Whoops! - Unfortunately there was an issue talking to Trello!</p>
                            <p>
                                Please refresh and try again, if that does not work then please contact support
                            </p>
                        </React.Fragment>
                    )
                }

                let mainDialogContent = getMenuContent(licenseDetails, selectedMenuItem)
                if (!mainDialogContent) {
                    if (!licenseDetails.isAuthorized || !licenseDetails.isLicensed()) {
                        licenseDetails.trelloIframeContext.modal({
                            url: './index.html?apptype=error',
                            fullscreen: true,
                            resizable: false,
                            title: 'Board Export by Kolekti',
                            accentColor: '#EBEDF0'
                        })
                        return (<div>Loading...</div>)
                    }
                    switch (selectedMenuItem) {
                        case MenuEnum.CUSTOMISATION:
                            mainDialogContent = <Customisation licenseDetails={licenseDetails}/>
                            break
                        case MenuEnum.EXPORT:
                        default:
                            mainDialogContent = <BoardExportContent licenseDetails={licenseDetails}
                                                                    setSelectedMenuItem={setSelectedMenuItem}/>
                            break
                    }
                }

                return (
                    <Grid container className={classes.container}>
                        <LeftBar selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem}
                                 externalButtons={menuButtons}/>
                        {mainDialogContent}
                    </Grid>
                )
            }}
        </LicenseDetailsContext.Consumer>
    )
}

export default BoardExportDialog