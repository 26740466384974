const URLREGEX = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/ig

/**
 * Utility function to split long urls that don't get wrapped in a page
 *
 * @param text
 * @param limit
 * @returns new string with split if necessary
 */
export function findSplitAndReplaceUrls(text: string, limit: number = 60) {
    return text.replace(URLREGEX, function (url) {
        if (url.length > limit) {
            const re = new RegExp(`.{1,${limit}}`, 'g')
            // @ts-ignore
            url = url.match(re).join('\n')
        }
        return url
    })
}

/**
 * Utility function to split long text that don't get wrapped in a page
 *
 * @param text to split
 * @param limit length word to split
 * @returns new string with split if necessary
 */
export const splitTextByLimit = (text: string = '', limit: number = 60) => {
    return text
        .split(/\s+/)
        .map(word => {
            if (_isUrl(word)) {
                return findSplitAndReplaceUrls(word, limit)
            }
            if (word.length >= limit) {
                const splittedWords = _applySplitByLimit(word, limit)
                return splittedWords == null ? '' : splittedWords.join(' ')
            } else {
                return word
            }
        }).join(' ')
}

/**
 * Utility function to split a text with a number of characters
 *
 * @param text to split
 * @param limit max characters to split
 * @returns array split word in parts
 */
const _applySplitByLimit = (text: string, limit: number) => {
    const regex = new RegExp(`.{1,${limit}}`, 'g')
    return text.match(regex)
}

/**
 * Check if a text is an url or not
 *
 * @param text to check
 * @returns boolean if a text is an url or not
 */
const _isUrl = (text: string) => {
    return text.match(URLREGEX)
}
