/**
 * Responsible for filtering content that trello provides via the Client Library or REST API. Allows us to use a sub-set of the 
 * data returned from either of those resources.
 */
class ContentFilter {

    /**
     * Filter the given list 'allLists' and return only those lists who have been selected by name.
     * @param {*} listIdsToKeep The ids of the lists we want to export.
     * @param {*} allLists All the possible lists on the board.
     */
    filterLists(listIdsToKeep, allLists) {
        let filteredLists = [];
        for (let step = 0; step < allLists.length; step++) {
            let list = allLists[step];
            if (listIdsToKeep.includes(list['id'])) {
                filteredLists.push(list);
            }
        }
        return filteredLists;
    }
}

export default ContentFilter;