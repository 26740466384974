import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles( {
    appTitle: {
        height: '29px',
        color: '#172B4E',
        fontFamily: "SF Pro Display Bold",
        fontSize: '24px',
        letterSpacing: 0,
        lineHeight: '29px',
    },
    contentContainer: {
        padding: '31px 48px 31px 48px',
    }
  }
)


export { useStyles }
