import {calculatePercentageForChecklist} from './Calculations'
import {dateTimeTransformer, excelDateTimeTransformer, LabelColors} from "./Transformers"

export function customFieldCSVExpander(cardDataObj, trelloData) {
    const card = trelloData.card
    const board = trelloData.board

    const customFields = card['customFieldItems']

    if (customFields && customFields.length > 0) {
        // Retreive the custom field information from the board
        const fullCustomFields = board.customFields
        const customFieldMap = _mapCustomFieldNamesToValues(customFields, fullCustomFields)

        // Load custom field values into card data
        for (const customField of customFieldMap) {
            cardDataObj[`Custom Field: ${customField.name}`] = customField.value
        }
    }
}

export function customFieldPDFExpander(cardDataObj, trelloData) {
    const card = trelloData.card
    const board = trelloData.board

    const customFields = card['customFieldItems']

    if (customFields && customFields.length > 0) {
        // Retreive the custom field information from the board
        const fullCustomFields = board.customFields
        cardDataObj['customFieldItems'] = _mapCustomFieldNamesToValues(customFields, fullCustomFields, false, true)
    }
}


/**
 * Fill cardDataObj given with and object that contains a formated text with all custom fields for trelloData.card
 * @param cardDataObj the object to fill
 * @param trelloData object that contains all needed info to export, like members, lists, cards...
 */
export function customFieldExcelExpander(cardDataObj, trelloData) {
    const card = trelloData.card
    const board = trelloData.board

    const customFields = card['customFieldItems'] ? card['customFieldItems'] : []

    // Retrieve the custom field information from the board
    const fullCustomFields = board.customFields
    const customFieldMap = _mapCustomFieldNamesToValues(customFields, fullCustomFields, true)

    if (trelloData.customisationData && trelloData.customisationData.customFieldsCustomisation) {
        customFieldMap.forEach((customField) => {
            cardDataObj[`Custom Field: ${customField.name}`] = {
                richText: [
                    {
                        text: `${customField.value.text ? customField.value.text : customField.value}`,
                        font: {
                            name: 'SF Pro Text Regular',
                            size: 14,
                            color: {argb: customField.value.color ? (LabelColors.find(labelColor => labelColor.color === customField.value.color)?.argbColor || '000000') : ''}
                        }
                    }
                ]
            }
        })
        return
    }

    // Load custom field values into card data
    cardDataObj['customFieldItems'] = {
        richText: customFieldMap.map((customField, index) =>
            [
                {
                    text: `${customField.name} - `,
                    font: {
                        name: 'SF Pro Text Regular',
                        size: 14,
                        bold: true
                    }
                },
                {
                    text: `${customField.value.text ? customField.value.text : customField.value}\n`,
                    font: {
                        name: 'SF Pro Text Regular',
                        size: 14,
                        color: {argb: customField.value.color ? (LabelColors.find(labelColor => labelColor.color === customField.value.color)?.argbColor || '000000') : ''}
                    }
                }
            ]
        ).flat()
    }
}

/**
 * Generate a list of objects that contains the name and the value
 * @param cardCustomFields {Array<any>} card custom field values
 * @param fullCustomFields {Array<any>} all board custom fields
 * @param isExcel {boolean} if it's true, then we format values in a different way than CSV format
 * @return {name, value}
 * @private
 */
const _mapCustomFieldNamesToValues = (cardCustomFields, fullCustomFields, isExcel, isPdf = false) => {
    if (fullCustomFields && fullCustomFields.length > 0) {
        return fullCustomFields.map(boardCustomField =>
            ({
                name: boardCustomField.name,
                type: boardCustomField.type,
                value: isExcel ?
                    _getCustomFieldExcelValue(boardCustomField, cardCustomFields.find(customField => customField.idCustomField === boardCustomField.id)) :
                    _getCustomFieldValue(boardCustomField, cardCustomFields.find(customField => customField.idCustomField === boardCustomField.id), isPdf)
            })
        )
    } else return []
}

const _getCustomFieldValue = (boardCustomField, customField, isPdf) => {
    if (!customField) return ''

    if (customField.value) {
        const valueKey = Object.keys(customField.value)[0]
        return customField.value[valueKey]
    }

    // No key found, retrieve from
    const dropdownOptionKey = customField.idValue
    const optionSelected = boardCustomField.options ? boardCustomField.options.filter(option => option.id === dropdownOptionKey) : []

    if (optionSelected.length !== 1) {
        console.log('Error reconciling the correct selected option for a dropdown custom field')
        return ''
    }

    if (isPdf) return {
        text: optionSelected[0].value.text,
        color: optionSelected[0].color
    }
    else return optionSelected[0].value.text
}

/**
 * Generate a string/object with custom field value formated.
 * @param boardCustomField the board custom field instance, witch contains list elements if the customfield is a select list
 * @param customField the card custom field with the value
 * @return {string|{color, text}}
 * @private
 */
const _getCustomFieldExcelValue = (boardCustomField, customField) => {
    if (boardCustomField.type === 'checkbox') {
        return `Checkbox (${customField?.value?.checked === 'true' ? 'Checked' : 'Unchecked'})`
    }

    if (!customField) return ''

    if (boardCustomField.type === 'date') {
        return dateTimeTransformer(customField.value?.date)
    }

    if (customField.value) {
        const valueKey = Object.keys(customField.value)[0]
        return customField.value[valueKey]
    }

    // No key found, retrieve from
    const dropdownOptionKey = customField.idValue
    const optionSelected = boardCustomField.options?.filter(option => option.id === dropdownOptionKey)

    if (!optionSelected || optionSelected.length !== 1) {
        console.log('Error reconciling the correct selected option for a dropdown custom field')
        return ''
    }

    return {
        text: optionSelected[0].value.text,
        color: optionSelected[0].color
    }
}

export function cardProgressExpander(cardDataObject, trelloData) {
    const card = trelloData.card

    const totalItems = card.badges.checkItems
    const completedItems = card.badges.checkItemsChecked

    const percentage = calculatePercentageForChecklist(totalItems, completedItems)

    if (totalItems || completedItems) cardDataObject['Complete (%)'] = percentage
}

/**
 * Fill cardDataObject with list names given on trelloData
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 */
export function listNameExpander(cardDataObject, trelloData) {
    // archived card
    if (trelloData.card.closed) {
        cardDataObject.listName = '-'
    } else {
        const list = trelloData.list
        if (list) cardDataObject.listName = list.name
    }
}

/**
 * Fill cardDataObject with the board name
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 */
export function boardNameExpander(cardDataObject, trelloData) {
    if (trelloData.board) cardDataObject.boardName = trelloData.board.name
}

/**
 * Fill cardDataObject with the due status
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 */
export function excelDueStatusExpander(cardDataObject, trelloData) {
    const date = trelloData.card.due
    if (date) {
        let statusText = ''
        let statusColor = ''
        if (trelloData.card.dueComplete) {
            statusText = 'Complete'
            statusColor = '61BD4F'
        } else {
            const isOverdue = new Date(date).getTime() < new Date().getTime()
            if (isOverdue) {
                statusText = 'Overdue'
                statusColor = 'FC902E'
            } else {
                const tomorrow = new Date()
                tomorrow.setDate(tomorrow.getDate() + 1)
                const isDueSoon = new Date(date).getTime() < tomorrow.getTime()
                if (isDueSoon) {
                    statusText = 'Due soon'
                    statusColor = 'F2D600'
                }
            }
        }
        cardDataObject.dueStatus = {
            richText: [{
                text: statusText,
                font: {
                    color: {'argb': statusColor},
                    bold: true,
                    name: 'SF Pro Text Regular',
                    size: 14
                }
            }]
        }
    }
}

/**
 * Fill cardDataObject with the comments related to the card
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 */
export function commentsExpander(cardDataObject, trelloData) {
    if (trelloData.actionsByCards)
        cardDataObject.comments = trelloData.actionsByCards.filter((action) => ['commentCard', 'copyCommentCard'].includes(action.type))
}

/**
 * Fill cardDataObject with the history related to the card
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 */
export function activityExpander(cardDataObject, trelloData) {
    if (trelloData.actionsByCards)
        cardDataObject.activity = trelloData.actionsByCards.filter((action) =>
            !['commentCard', 'copyCommentCard'].includes(action.type) && action.type !== 'updateChecklist' && ((action.type === 'updateCard' &&
                    (action.data?.card?.due || action.data?.old?.due || action.data?.customFieldItem || action.data?.listBefore || action.data?.listAfter))
                || action.type !== 'updateCard')
        )
}

/**
 * Fill cardDataObject with the board ID
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 */
export function boardIdExpander(cardDataObject, trelloData) {
    if (trelloData.board) cardDataObject.idBoard = trelloData.board.id
}

/**
 * Fill cardDataObject with the number of votes that a card has
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 */
export function votesExpander(cardDataObject, trelloData) {
    if (trelloData.card && trelloData.card.badges) cardDataObject.votes = trelloData.card.badges.votes
}

/**
 * Fill cardDataObject with a string that contains the number of comments that a card has
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 * @return a string with the following format: X comments
 */
export function commentsLengthExpander(cardDataObject, trelloData) {
    cardDataObject.comments = trelloData && trelloData.card && trelloData.card.badges &&
    trelloData.card.badges.comments ? `${trelloData.card.badges.comments} comments` : '0 comments'
}

/**
 * Fill cardDataObject with a string that contains if the card is archived or not
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 * @return a string text to indicate if the card is archived or not
 */
export function archivedCardExpander(cardDataObject, trelloData) {
    cardDataObject.archivedCard = (trelloData && trelloData.card.closed) ? 'Archived' : 'N/A'
}

/**
 * Fill cardDataObject in Pdf file with a string that contains if the card is archived or not
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 * @return a string text to indicate if the card is archived or not
 */
export function archivedCardPDFExpander(cardDataObject, trelloData) {
    cardDataObject.archivedCard = (trelloData && trelloData.card.closed) ? 'Archived card' : ''
}

/**
 * Fill cardDataObject with a string that contains the creation card date
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 * @return a string text that date
 */
export function dateCardCreation(cardDataObject, trelloData) {
    const creationDate = trelloData.actionsByCards?.find((action) => ['copyCard', 'createCard'].includes(action.type))?.date
    cardDataObject.creationDate = creationDate ? excelDateTimeTransformer(creationDate) : ''
}

/**
 * Fill cardDataObject in Pdf file with a string that contains the creation card date
 * @param cardDataObject object to fill
 * @param trelloData object that contains lists info
 * @return a string text that date
 */
export function dateCardPDFCreation(cardDataObject, trelloData) {
    const creationDate = trelloData.actionsByCards?.find((action) => ['copyCard', 'createCard'].includes(action.type))?.date
    cardDataObject.creationDate = creationDate ? dateTimeTransformer(creationDate) : ''
}