import React from 'react'
import {Document, Font, Link, Page, Text, View} from '@react-pdf/renderer'
import {PDFExportDocumentProperty} from '../../../types/PDFExportTypes'
import PDFExportPage from './PDFExportPage'
import {createStyle, fontFamilyOtherLanguages, getFontNames} from './PDFStyles'
import MPLUSRounded1cLight from '../../../fonts/MPLUSRounded1c/MPLUSRounded1c-Light.ttf'
import MPLUSRounded1cRegular from '../../../fonts/MPLUSRounded1c/MPLUSRounded1c-Regular.ttf'
import MPLUSRounded1cBold from '../../../fonts/MPLUSRounded1c/MPLUSRounded1c-Bold.ttf'
import NotoSansArabicBold from '../../../fonts/NotoSansArabic/NotoSansArabic-Bold.ttf'
import NotoSansArabicLight from '../../../fonts/NotoSansArabic/NotoSansArabic-Light.ttf'
import NotoSansArabicRegular from '../../../fonts/NotoSansArabic/NotoSansArabic-Regular.ttf'
import NotoSansSCBold from '../../../fonts/NotoSansSC/NotoSansSC-Bold.otf'
import NotoSansSCLight from '../../../fonts/NotoSansSC/NotoSansSC-Light.otf'
import NotoSansSCRegular from '../../../fonts/NotoSansSC/NotoSansSC-Regular.otf'
import RobotoBold from '../../../fonts/Roboto/Roboto-Bold.ttf'
import RobotoLight from '../../../fonts/Roboto/Roboto-Light.ttf'
import RobotoItalic from '../../../fonts/Roboto/Roboto-Italic.ttf'
import RobotoMonoRegular from '../../../fonts/RobotoMono/RobotoMono-Regular.ttf'
import RalewayBold from '../../../fonts/Raleway/Raleway-Bold.ttf'
import RalewayLight from '../../../fonts/Raleway/Raleway-Light.ttf'
import RalewayItalic from '../../../fonts/Raleway/Raleway-Italic.ttf'


Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: RobotoBold,
                fontWeight: 700
            },
            {
                src: RobotoItalic,
                fontStyle: 'italic'
            },
            {
                src: RobotoLight,
                fontWeight: 'light'
            }
        ]
    }
)

Font.register({
        family: 'Raleway',
        fonts: [
            {
                src: RalewayBold,
                fontWeight: 700
            },
            {
                src: RalewayLight,
                fontWeight: 'light'
            },
            {
                src: RalewayItalic,
                fontStyle: 'italic'
            }
        ]
    }
)


Font.register({
        family: 'MPLUSRounded1c', //Japanese
        fonts: [
            {
                src: MPLUSRounded1cLight
            },
            {
                src: MPLUSRounded1cBold
            },
            {
                src: MPLUSRounded1cRegular
            }
        ]
    }
)

Font.register({
        family: 'NotoSansArabic', //Arabic
        fonts: [
            {
                src: NotoSansArabicLight,
                fontWeight: 'light'
            },
            {
                src: NotoSansArabicBold,
                fontWeight: 700
            },
            {
                src: NotoSansArabicRegular
            }
        ]
    }
)


Font.register({
        family: 'Noto Sans Simplified Chinese', //Chinese
        fonts: [
            {
                src: NotoSansSCBold,
                fontWeight: 700
            },
            {
                src: NotoSansSCLight,
                fontWeight: 'light'
            },
            {
                src: NotoSansSCRegular
            }
        ]
    }
)

Font.register({
        family: 'robotomono',
        fonts: [
            {
                src: RobotoMonoRegular
            }
        ]
    }
)

/**
 * A 'Document' containing pages that map to cards in Trello.
 */
function PDFExportDocument(props: PDFExportDocumentProperty) {
    let values: any[] = []
    const getValues = (obj: any) => {
        Object.keys(obj).forEach(key => {
            values.push(obj[key])
            if (typeof obj[key] === 'object' && obj[key]) {
                getValues(obj[key])
            }
        })
        return values
    }

    const pages: any[] = props.cards.map((cardData, index) => {
        values = []
        const allTextCard = getValues(cardData).join()
        const fontNames = getFontNames(allTextCard)
        const styles = createStyle(fontNames)
        return <PDFExportPage
            key={index}
            style={styles}
            selectedFields={props.selectedFields}
            card={cardData}/>
    })

    if (props.isOnlyEvalLicensed) {
        const regularStyle = createStyle([fontFamilyOtherLanguages])
        pages.push(
            <Page size="A4" style={{...regularStyle.paddingVerticalInPage}} key="evaluation">
                <View style={{marginTop: 20, padding: 30}}>
                    <Text style={{...regularStyle.evaluation}}>
                        {process.env.REACT_APP_EVAL_TEXT_1}
                    </Text>
                    <Text style={{...regularStyle.evaluation, marginTop: 20}}>
                        {process.env.REACT_APP_EVAL_TEXT_3}
                    </Text>
                    <Text style={{...regularStyle.evaluation, marginTop: -10}}>
                        <Link style={{...regularStyle.link}} src={process.env.REACT_APP_EVAL_LIMIT_DOCS_LINK!}>
                            {process.env.REACT_APP_EVAL_TEXT_4}
                        </Link>
                    </Text>
                </View>
            </Page>)
    }

    return <Document>
        {pages}
    </Document>
}

export default PDFExportDocument