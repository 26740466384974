import React from 'react'
import {PDFExportPageChecklistsProperty} from '../../../../types/PDFExportTypes'
import PDFExportChecklistItem from './PDFExportPageChecklistItem'

function PDFExportPageChecklists(props: PDFExportPageChecklistsProperty) {
    const checklists = props.checklists.map((checklist, index) => {
        return <PDFExportChecklistItem key={index} style={props.style} checklist={checklist}/>
    })
    return <React.Fragment>
        {checklists}
    </React.Fragment>
}

export default PDFExportPageChecklists