import React from 'react'
import ListItem from '../listItem/ListItem'
import {Box, FormControlLabel} from '@material-ui/core'
import {useStyles} from "./ListSelectorStyles"
import {Checkbox} from '@atlaskit/checkbox'
import SelectFormatModal from "../selectFormatModal/SelectFormatModal"
import '../Orah.css'

/**
 * A React JS component that is responsible for rendering a list of lists on the current board that need to be rendered.
 */
const ListSelector = (props) => {

    const classes = useStyles()

    const {lists, selected} = props

    const isListSelected = (list) => {
        if (selected && selected.find(element => element.id === list.id)) {
            return selected.find(element => element.id === list.id).selected
        }
    }

    return (
        <Box className={classes.listSelectorContainer}>
            <FormControlLabel className={classes.selectAllLabel} label="Select all" control={
                <Checkbox
                    isChecked={props.selectAll}
                    onChange={props.handleSelectAllListItems}
                    name="checkAll"
                    testId="checkAll"
                />}
            />
            <FormControlLabel className={classes.selectAllLabel} label="Include archived cards" control={
                <Checkbox
                    isChecked={props.includeArchivedCards}
                    onChange={props.handleIncludeArchivedCards}
                    name="includeArchivedCards"
                    testId="includeArchivedCards"
                />}
            />
            <Box display="flex" flexWrap="wrap">
                {lists.map((list) => {
                    return <ListItem id={list.id}
                                     key={list.id}
                                     listName={list.name}
                                     cardsLength={list.cards.length}
                                     selected={isListSelected(list)}
                                     onListSelected={props.handleListItemSelected}/>
                })}
            </Box>
            <SelectFormatModal {...props}/>
        </Box>
    )
}

export default ListSelector