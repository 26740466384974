import React from 'react'
import {Image, Text, View} from '@react-pdf/renderer'
import {PDFExportPageCustomFieldItemProperty} from '../../../../types/PDFExportTypes'
import {dateTimeTransformer, LabelColors} from '../../../../modules/utility/Transformers'

/**
 * Render the custom field value based on the custom field type
 * @param props customField info and styles
 */
function PDFExportPageCustomFieldValue(props: PDFExportPageCustomFieldItemProperty) {
    const {customField, style} = props
    switch (customField.type) {
        case 'checkbox':
            return <View>
                <Image
                    src={customField.value === 'true' ? '/icons/pdf-export/CheckBoxIcon.png' : '/icons/pdf-export/CheckBoxOutlineBlankIcon.png'}
                    style={style.customFieldIcon}/>
            </View>
        case 'date':
            return <Text style={style.checklistElementText}>{dateTimeTransformer(customField.value)}</Text>
        case 'list':
            const customFieldValue = customField.value
            if (customFieldValue instanceof Object && 'color' in customFieldValue) {
                const argbColor = LabelColors.find(labelColor => labelColor.color === customFieldValue.color)?.argbColor || 'FFFFFF'
                return <View style={style.checklistElementText}>
                    <View style={[style.labelItemRect, {backgroundColor: '#' + argbColor}]}>
                        <Text style={argbColor === 'FFFFFF' ? style.customFieldListItemNoColorText : style.labelItemText}>{customFieldValue.text}</Text>
                    </View>
                </View>
            } else {
                return <Text style={style.checklistElementText}>{customField.value}</Text>
            }
        case 'number':
        case 'text':
            return <Text style={style.checklistElementText}>{customField.value}</Text>
    }
}

export default PDFExportPageCustomFieldValue