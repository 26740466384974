import React from 'react'
import {Image} from '@react-pdf/renderer'
import {PDFExportPageCustomFieldItemProperty} from '../../../../types/PDFExportTypes'

/**
 * Render the icon baesd on the customFieldType
 * @param props customField info and styles
 */
function PDFExportPageCustomFieldIcon(props: PDFExportPageCustomFieldItemProperty) {
    const {customField, style} = props
    switch (customField.type) {
        case 'checkbox':
            return <Image src='/icons/pdf-export/check-circle-outline.png' style={style.customFieldIcon}/>
        case 'date':
            return <Image src='/icons/pdf-export/calendar.png' style={style.customFieldIcon}/>
        case 'list':
            return <Image src='/icons/pdf-export/bullet-list.png' style={style.customFieldIcon}/>
        case 'number':
            return <Image src='/icons/pdf-export/number.png' style={style.customFieldIcon}/>
        case 'text':
            return <Image src='/icons/pdf-export/text.png' style={style.customFieldIcon}/>
    }
}

export default PDFExportPageCustomFieldIcon