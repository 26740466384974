import React from 'react';
import {Box} from "@material-ui/core";
import {useStyles} from "./ListItemStyles";
import {FormControlLabel} from "@material-ui/core";
import {Typography} from "@material-ui/core";
import { Checkbox } from '@atlaskit/checkbox';

/**
 * An item in the list that displays a Trello List Name as a selectable option.
 */
const ListItem = (props) => {

    const classes = useStyles()

    const handleChange = (event) => {
        props.onListSelected(props.id, event.target.checked)
    }

    return (
        <Box className={classes.itemBox}>
            <Box className={classes.selectListContainer}>
                <Box display="flex" >
                    <Box flexGrow={1}>
                        <Typography className={classes.listName} color="textSecondary" gutterBottom>
                            {props.listName}
                        </Typography>
                    </Box>
                    <Box >
                        <Typography className={classes.cardsLength} color="textSecondary" gutterBottom>
                            {props.cardsLength} cards
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.exportCheck}>
                    <FormControlLabel label="Export this list" className={classes.selectItemLabel}  control={
                        <Checkbox
                            isChecked={props.selected}
                            onChange={handleChange}
                            name={props.listName}
                            id={props.id}
                        />}
                    />
                </Box>
            </Box>
        </Box>

    )
}

export default ListItem;