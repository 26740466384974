import React from 'react'
import {Image, View} from '@react-pdf/renderer'
import PDFExportPageTitle from '../basic/PDFExportPageTitle'
import PDFExportPageActivity from './PDFExportPageActivity'
import {PDFExportPageActivitiesProperty} from '../../../../types/ActivityTypes'

function PDFExportPageActivities(props: PDFExportPageActivitiesProperty) {
    const allItems = props.activity.map((singleActivity, index: number) => {
        return <PDFExportPageActivity key={index} singleActivity={singleActivity} style={props.style}/>
    })

    return <React.Fragment>
        <View style={{...props.style.bannerItemRow, marginTop: 20}}>
            <View style={props.style.descriptionIcon}>
                <Image src="/icons/pdf-export/bullet-list.png"/>
            </View>
            <PDFExportPageTitle
                style={props.style}
                styleTitle={props.style.h2}
                title="Activity:"/>
        </View>
        {allItems}
    </React.Fragment>
}

export default PDFExportPageActivities