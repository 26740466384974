import ContentFilter from '../utility/ContentFilter'
import DataFormatterImpl from '../utility/DataFormatterImpl'

import {
    archivedCardExpander,
    boardIdExpander,
    boardNameExpander,
    votesExpander,
    listNameExpander,
    customFieldExcelExpander,
    commentsExpander,
    activityExpander,
    excelDueStatusExpander, dateCardCreation
} from '../utility/Expanders'
import {checklistExcelDataAppender} from '../utility/Appenders'
import FileSaver from 'filesaver-js'
import {getChecklistFieldNames, getCustomFieldNames} from '../utility/Calculations'
import {
    attachmentsTransformer,
    dateTimeTransformer,
    descriptionTransformer,
    excelDueTransformer,
    generateActivityText,
    labelsWithColorTransformer,
    membersTransformer,
    urlExcelTransformer
} from '../utility/Transformers'
import {getAllCustomisationData} from '../../components/customisation/CustomisationUtils'
import {getCardsToExportForEvaluation, isNewEvaluationLicensed} from '../utility/Utils'

const ExcelJS = require('exceljs')


class MSExcelExport {
    HEADERS = ['id',
        'idShort',
        'name',
        'url',
        'desc',
        'labels',
        'members',
        'creationDate',
        'due',
        'dueStatus',
        'attachments',
        'votes',
        'comments',
        'activity',
        'dateLastActivity',
        'address',
        'idList',
        'listName',
        'idBoard',
        'boardName',
        'customFieldItems'
    ]

    hyperlinkFont = {
        name: 'SF Pro Text Regular',
        size: 14,
        underline: true,
        color: {argb: '3A67AF'}
    }


    constructor(fieldsSelected) {
        // --== File Format ==--
        this.FILE_TYPE = 'application/octet-stream'

        this.contentFilter = new ContentFilter()

        const fieldNames = fieldsSelected.map(field => field.value)
        // dueStatus is not selectable in the dropdown. It goes together with due (Due Date)
        if (fieldNames.includes('due')) {
            fieldNames.push('dueStatus')
            fieldsSelected.push({label: 'Due Date Status', value: 'dueStatus'})
        }

        // Fields that need transformational formatting (i.e. Objects / complex types)
        let transformationalFields = {
            'members': membersTransformer,
            'labels': labelsWithColorTransformer,
            'due': excelDueTransformer,
            'url': urlExcelTransformer,
            'attachments': attachmentsTransformer,
            'dateLastActivity': dateTimeTransformer,
            'desc': descriptionTransformer
        }
        transformationalFields = Object.keys(transformationalFields)
            .filter(key => fieldNames.includes(key))
            .reduce((obj, key) => {
                obj[key] = transformationalFields[key]
                return obj
            }, {})

        // Fields that need expansion (i.e. compositing data from other sources)
        let expansionFields = {
            'customFieldItems': customFieldExcelExpander,
            'listName': listNameExpander,
            'votes': votesExpander,
            'idBoard': boardIdExpander,
            'boardName': boardNameExpander,
            'dueStatus': excelDueStatusExpander,
            'comments': commentsExpander,
            'activity': activityExpander,
            'archivedCard': archivedCardExpander,
            'creationDate': dateCardCreation
        }
        expansionFields = Object.keys(expansionFields)
            .filter(key => fieldNames.includes(key))
            .reduce((obj, key) => {
                obj[key] = expansionFields[key]
                return obj
            }, {})
        this.dataFormatter = new DataFormatterImpl(
            this.HEADERS.filter(header => fieldNames.includes(header)), // <- Fields that we want to use from the cards
            transformationalFields,
            expansionFields,
            [
                checklistExcelDataAppender
            ]
        )
    }

    /**
     * Export the cards and all their field values (including custom) from the lists identified by the given list names.
     * @param {*} listIds The ids of the lists we want to export data for.
     * @param {*} trelloData An object containing all of the required data from Trello.
     * @param {Array<{label: string, value: string}>} fieldsSelected A list of objects with the selected fields to export
     * @param licenseDetails object to get current memberId and trello app key
     */
    async export(listIds, trelloData, fieldsSelected, licenseDetails) {
        // 1. Filter the lists to return ONLY the ones specified
        const filteredLists = this.contentFilter.filterLists(listIds, trelloData.lists)
        const customisationData = await getAllCustomisationData()

        // 2. Compile a list of all of the cards in those lists
        const filteredCardData = await this.dataFormatter.getCardDataFromLists({
            list: filteredLists,
            customisationData: customisationData,
            ...trelloData
        }, fieldsSelected.map(field => field.value), licenseDetails)

        // 3. Generate Excel output using the xlsx library
        const xlsxOutput = await this._createXlsxFromCards(filteredCardData, fieldsSelected, trelloData.board.name, customisationData, licenseDetails)
        // 4. Save to file using FileSaver
        const currentDate = (new Date()).toISOString().substring(0, 10).replace('-', '').replace('-', '')
        const fileName = trelloData.board.name + ' ' + currentDate + '.xlsx'
        const blob = new Blob([xlsxOutput], {type: this.FILE_TYPE})
        FileSaver.saveAs(blob, fileName)

        this.dataFormatter.fieldsToOutput = this.HEADERS
    }

    /**
     * Converts the given array of JS Objects into a XSLX data object that can be written to a file.
     * @param {*} listOfCardData A list of card data that we want to convert to XSLX format.
     * @param {Array<{label: string, value: string}>} fieldsSelected A list of objects {label, value} with the selected fields to export
     * @param {*} boardName name of board
     * @param customisationData customization config to apply
     * @param licenseDetails object to get current memberId and trello app key
     */
    async _createXlsxFromCards(listOfCardData, fieldsSelected, boardName, customisationData, licenseDetails) {


        // Create the Worksheet and Workbook
        const checklistFieldNames = getChecklistFieldNames(listOfCardData)
        const customFieldNames = getCustomFieldNames(listOfCardData)
        const orderedHeaders = this._getHeadersOrdered(fieldsSelected, checklistFieldNames, customFieldNames, customisationData)
        const workbook = new ExcelJS.Workbook()
        let sheet = workbook.addWorksheet(boardName ? this._replaceSpecialCharacters(boardName) : boardName)

        this._createSheetTitle(sheet, orderedHeaders.length, boardName)
        this._createColumnHeaders(sheet, orderedHeaders, fieldsSelected)

        let cardsData = listOfCardData

        if (isNewEvaluationLicensed(licenseDetails)) {
            cardsData = this._filterByMaxEvalCards(cardsData)
        }
        
        sheet.insertRows(3, cardsData)

        this._createStylesForDataRows(sheet, orderedHeaders)
        if (fieldsSelected.find(field => field.value === 'comments' || field.value === 'activity')) {
            this._generateCommentsAndActivityCardInSheet(workbook, sheet, boardName, cardsData)
        }

        if (isNewEvaluationLicensed(licenseDetails)) {
            this._createEvaluationData(sheet)
        }

        this.dataFormatter.fieldsToOutput = this.HEADERS

        return await workbook.xlsx.writeBuffer()
    }

    /**
     * Filter max cards for eval users
     * @param cardsData cardsData A list of card data
     * @returns {any[]} list of cards to display
     **/
    _filterByMaxEvalCards(cardsData) {
        const archivedCards = cardsData.filter(card => card.archivedCard !== 'N/A')
        const nonArchivedCards = cardsData.filter(card => card.archivedCard === 'N/A')
        const maxCardsToExport = parseInt(process.env.REACT_APP_EXPORT_EXCEL_CARDS_CARDS || '5')
        const maxNonArchivedCardsToExport = parseInt(process.env.REACT_APP_EVAL_EXCEL_MAX_NON_ARCHIVED_CARDS || '3')
        const maxArchivedCardsToExport = parseInt(process.env.REACT_APP_EVAL_EXCEL_MAX_ARCHIVED_CARDS || '2')

        return getCardsToExportForEvaluation(nonArchivedCards, archivedCards, maxCardsToExport, maxNonArchivedCardsToExport, maxArchivedCardsToExport)
    }

    /**
     * Generate Comment and Activity worksheet name to avoid repeated names
     * @param workbook{Workbook} logical excel file representation to check if already exists a sheet with that name
     * @param idShort{number} the card's short ID
     * @return {string} the generated name
     */
    _generateCommentAndActivityWorksheetName(workbook, idShort) {
        let worksheetName = `Comments & activity ${idShort ?? `_0`}`
        let index = 1
        while(workbook.getWorksheet(worksheetName)) {
            worksheetName = `Comments & activity ${idShort ?? ''}${`_${index}`}`
            index++
        }
        return worksheetName
    }

    /**
     * Generate the comment and activity sheet
     * @param workbook{Workbook} logical excel file representation to create sheets
     * @param cardsSheet{Worksheet} sheet with card data
     * @param boardName{string} board name to create link to card sheet
     * @param listOfCardData{Array<any>} Array with objects that contains all card data needed to fill the excel file
     */
    _generateCommentsAndActivityCardInSheet(workbook, cardsSheet, boardName, listOfCardData) {
        let commentsColumn = 0
        let activityColumn = 0
        cardsSheet.getRow(2).eachCell((cell, colNumber) => {
            if (cell.toString() === 'Comments') commentsColumn = colNumber
            if (cell.toString() === 'Activity') activityColumn = colNumber
        })
        listOfCardData.forEach((cardData, rowNum) => {
            const commentsAndActivitySheetName = this._generateCommentAndActivityWorksheetName(workbook, cardData.idShort)
            const commentsAndActivitySheet = workbook.addWorksheet(commentsAndActivitySheetName)
            this._addCommentAndActivityRows(boardName, cardData, commentsAndActivitySheet, rowNum)
            this._setSheetHeaderStyleToRow(commentsAndActivitySheet.getRow(2))

            if (commentsColumn) {
                this._setSheetTitleStyles(commentsAndActivitySheet.getRow(1), [commentsAndActivitySheet.getCell("A1")])
                const commentsColumns = ['A2', 'B2', 'C2']
                commentsColumns.forEach((cell, index) =>
                    this._setColumnHeaderStyle(commentsAndActivitySheet.getColumn(index + 1), commentsAndActivitySheet.getCell(cell), 5 * 2)
                )
                commentsAndActivitySheet.getCell("B1").font = {...this.hyperlinkFont, size: 20, bold: true}
                this._updateCommentCell(cardsSheet, cardData, commentsAndActivitySheetName, rowNum, commentsColumn)
            }
            if (activityColumn) {
                this._setSheetTitleStyles(commentsAndActivitySheet.getRow(1), [commentsAndActivitySheet.getCell("G1")])
                const activityColumns = ['G2', 'H2', 'I2']
                activityColumns.forEach((cell, index) =>
                    this._setColumnHeaderStyle(commentsAndActivitySheet.getColumn(index + 1), commentsAndActivitySheet.getCell(cell), 5 * 2)
                )
                commentsAndActivitySheet.getCell("H1").font = {...this.hyperlinkFont, size: 20, bold: true}
                this._updateActivityCell(cardsSheet, commentsAndActivitySheetName, rowNum, activityColumn)
            }
            this._createStylesForDataRows(commentsAndActivitySheet, [])
        })
    }

    /**
     * Replace special characters from value like asterisk (*), question mark (?),
     * colon (:), forward slash (/ \), or bracket ([]
     * @param value{string} value to replace
     * @return {string} value replaced
     */
    _replaceSpecialCharacters(value) {
        return value.replace(/[*?:/\\[\]]/g, '')
    }


    /**
     * Fill comment cell with a link to the corresponding sheet
     * @param cardsSheet{Worksheet} sheet with card data
     * @param cardData Object that contains all card data needed to fill the excel file
     * @param commentsAndActivitySheetName{string} sheet with comments and activity
     * @param rowNum{number} row number to fill activity cell
     * @param commentsColumn{string} cell coords where the comment for the given card is located
     */
    _updateCommentCell(cardsSheet, cardData, commentsAndActivitySheetName, rowNum, commentsColumn) {
        const commentsCell = cardsSheet.getCell(rowNum + 3, commentsColumn)
        commentsCell.value = {
            text: `${cardData.comments?.length ?? 0} comments`,
            hyperlink: `#'${commentsAndActivitySheetName}'!A${(rowNum + 3)}`,
            tooltip: `${cardData.comments?.length ?? 0} comments`
        }
        commentsCell.font = this.hyperlinkFont
    }

    /**
     * Fill activity cell with a link to the corresponding sheet
     * @param cardsSheet{Worksheet} sheet with card data
     * @param commentsAndActivitySheetName{string} sheet name with comments and activity
     * @param rowNum{number} row number to fill activity cell
     * @param activityColumn{number} cell coords where the activity for the given card is located
     */
    _updateActivityCell(cardsSheet, commentsAndActivitySheetName, rowNum, activityColumn) {
        const commentsCell = cardsSheet.getCell(rowNum + 3, activityColumn)
        commentsCell.value = {
            text: 'Activity',
            hyperlink: `#'${commentsAndActivitySheetName}'!G${(rowNum + 3)}`,
            tooltip: 'Activity'
        }
        commentsCell.font = this.hyperlinkFont
    }

    /**
     * Fill the given sheet with comments and/or activity data
     * @param boardName{string} board name to create link to card sheet
     * @param cardData Object that contains all card data needed to fill the excel file
     * @param commentsAndActivitySheet{Worksheet} sheet to add comment and/or activity
     * @param cardRowNum{number} row number to create the link to card sheet
     */
    _addCommentAndActivityRows(boardName, cardData, commentsAndActivitySheet, cardRowNum) {
        const cardLinkedCellContent = {
            text: `Card ${cardData.idShort ?? (cardRowNum + 1)}`,
            hyperlink: `#'${boardName}'!A${(cardRowNum + 3)}`,
            tooltip: `Card ${cardData.idShort ?? (cardRowNum + 1)}`
        }
        const cardCommentsAndActivityData = this._generateCommentAndActivityHeaders(cardData, cardLinkedCellContent)

        const commentsAndActivityLongestLenght = Math.max(cardData.comments?.length || 0, cardData.activity?.length || 0)
        for (let rowNum = 0; rowNum < commentsAndActivityLongestLenght; rowNum++) {
            const commentData = cardData.comments ? cardData.comments[rowNum] : undefined
            const activityData = cardData.activity ? cardData.activity[rowNum] : undefined

            if (commentData && activityData) {
                cardCommentsAndActivityData.push([commentData.memberCreator?.fullName || '[deleted account]', dateTimeTransformer(commentData.date), commentData.data.text,
                    '', '', '', activityData.memberCreator?.fullName || '[deleted account]', dateTimeTransformer(activityData.date), generateActivityText(activityData, cardData.boards)])
            } else if (commentData) {
                cardCommentsAndActivityData.push([commentData.memberCreator?.fullName || '[deleted account]', dateTimeTransformer(commentData.date), commentData.data.text])
            } else {
                cardCommentsAndActivityData.push(['', '', '', '', '', '', activityData.memberCreator?.fullName || '[deleted account]', dateTimeTransformer(activityData.date), generateActivityText(activityData, cardData.boards)])
            }
        }

        commentsAndActivitySheet.insertRows(1, cardCommentsAndActivityData)
    }

    /**
     * Generate headers for the Comments & Activity sheet
     * @param cardData Object that contains all card data needed to fill the excel file
     * @param cardLinkedCellContent object that contains a link to the card data row
     * @return {(string[])[]} An arary of arrays with the header strings
     */
    _generateCommentAndActivityHeaders(cardData, cardLinkedCellContent) {
        if (cardData.comments && cardData.comments.length > 0 && cardData.activity && cardData.activity.length > 0)
            return [
                ['Comments', cardLinkedCellContent, '', '', '', '', 'Activity', cardLinkedCellContent],
                ['Author', 'Date', 'Comment', '', '', '', 'Author', 'Date', 'Change']
            ]
        else if (cardData.comments && cardData.comments.length > 0)
            return [
                ['Comments', cardLinkedCellContent, '', '', '', '', '', ''],
                ['Author', 'Date', 'Comment', '', '', '', '', '', '']
            ]
        else if (cardData.activity && cardData.activity.length > 0)
            return [
                ['', '', '', '', '', '', 'Activity', cardLinkedCellContent],
                ['', '', '', '', '', '', 'Author', 'Date', 'Change']
            ]
        else return []
    }

    /**
     * Generate an array with ordered headers to generate Excel file
     * @param {Array<{label: string, value: string}>} fieldsSelected Array with selected fields to export
     * @param {*} checklistFieldNames an array with checklist field names from the list of card data given
     * @param customFieldNames an array with custom field names from the list of card data given
     * @param customisationData the customisation data related to the user
     * @return {String[]} string array with ordered header names
     */
    _getHeadersOrdered(fieldsSelected, checklistFieldNames, customFieldNames, customisationData) {
        let fieldNames = fieldsSelected.map(field => field.value)
        let headers = [
            'name',
            'id',
            'idShort',
            'url',
            'boardName',
            'idBoard',
            'listName',
            'idList',
            'members',
            'labels',
            'creationDate',
            'due',
            'dueStatus',
            'desc'
        ].filter(header => fieldNames.includes(header))

        if (fieldNames.includes('customFieldItems')) {
            customisationData && customisationData.customFieldsCustomisation ? headers = headers.concat(customFieldNames) : headers.push('customFieldItems')
        }

        if (fieldNames.includes('checklists')) headers = headers.concat(checklistFieldNames)
        headers = headers.concat([
            'checklistItemTotal',
            'checklistItemCompleted',
            'attachments',
            'address',
            'votes',
            'comments',
            'activity',
            'dateLastActivity',
            'archivedCard'
        ].filter(header => fieldNames.includes(header)))
        return headers
    }

    /**
     * Replace header names for card fields (without custom fields)
     * @param {Worksheet} sheet with card data
     * @param {string[]} orderedHeaders
     * @param {Array<{label: string, value: string}>} fieldsSelected
     */
    _createColumnHeaders(sheet, orderedHeaders, fieldsSelected) {
        if (sheet) {
            const headers = orderedHeaders.map(orderedHeader => {
                if (!orderedHeader.startsWith('Checklist') && orderedHeader !== 'Checklist Items Total' && orderedHeader !== 'Checklist Items Completed' && !orderedHeader.startsWith('Custom Field'))
                    return fieldsSelected.find(field => field.value === orderedHeader).label
                else return orderedHeader
            })
            const headersRow = sheet.getRow(2)
            this._setSheetHeaderStyleToRow(headersRow)
            this._setColumnsHeadersStyle(headers, sheet, headersRow, orderedHeaders)
        }
    }

    /**
     * Set sheet column styles and filling with its title
     * @param headers{string[]} selected headers to export
     * @param sheet{Worksheet} sheet to update
     * @param headersRow{Row} row to fill with headers
     * @param orderedHeaders{string[]} header values to match with data rows
     */
    _setColumnsHeadersStyle(headers, sheet, headersRow, orderedHeaders) {
        headers.forEach((header, index) => {
            const column = sheet.getColumn(index + 1)
            const headerCell = headersRow.getCell(index + 1)
            column.key = orderedHeaders[index]
            headerCell.value = header
            this._setColumnHeaderStyle(column, headerCell, header?.length ?? 5 * 2)
        })
    }

    /**
     * Fix column width and fill header cell with borders and background
     * @param column{Column} column to fix width
     * @param headerCell{Cell}
     * @param columnWidth{number} width to fix
     */
    _setColumnHeaderStyle(column, headerCell, columnWidth) {
        column.width = columnWidth
        headerCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'ECF1FF'}
        }
        headerCell.border = {
            top: {style: 'thin', color: {argb: '979797'}},
            left: {style: 'thin', color: {argb: '979797'}},
            bottom: {style: 'thin', color: {argb: '979797'}},
            right: {style: 'thin', color: {argb: '979797'}}
        }
    }

    /**
     * Set row height, font and vertical alignment
     * @param row{Row} row to update styles
     */
    _setSheetHeaderStyleToRow(row) {
        row.height = 27
        row.font = {
            name: 'SF Pro Text Semibold',
            size: 14
        }
        row.alignment = {
            vertical: 'middle'
        }
    }

    /**
     * Merge all cells for first row and set the title
     * @param sheet{Worksheet} sheet to update
     * @param headersCount{number} how many cells will be merged to show the title
     * @param sheetTitle{string} title to show
     */
    _createSheetTitle(sheet, headersCount, sheetTitle) {
        sheet.mergeCells(`A1:${sheet.getColumn(headersCount).letter}1`)
        const boardNameCell = sheet.getCell('A1')
        boardNameCell.value = ` ${sheetTitle}`
        this._setSheetTitleStyles(sheet.getRow(1), [boardNameCell])
    }

    /**
     * Set sheet title styles to create a big row with the title and fill it with blue background
     * @param row{Row} title row to create styles
     * @param blueCells{Cell[]} array of cells to set blue background
     */
    _setSheetTitleStyles(row, blueCells) {
        row.height = 42
        row.font = {
            name: 'SF Pro Display Medium',
            size: 20,
            color: {argb: 'FFFFFF'},
            bold: true
        }
        row.alignment = {
            vertical: 'middle'
        }
        blueCells.forEach(blueCell => {
            blueCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '0049B0'}
            }

        })
    }

    /**
     * Set styles for data rows for the given sheet
     * @param sheet{Worksheet} sheet to update
     * @param orderedHeaders{Array<string>} field list to set a link format to URL and Attachments
     */
    _createStylesForDataRows(sheet, orderedHeaders) {
        const urlColumn = orderedHeaders.findIndex(header => header === 'url') + 1
        const attachmentColumn = orderedHeaders.findIndex(header => header === 'attachments') + 1

        for (let rowNum = 3; rowNum <= sheet.rowCount; rowNum++) {
            const dataRow = sheet.getRow(rowNum)
            dataRow.font = {
                name: 'SF Pro Text Regular',
                size: 14
            }

            dataRow.border = {
                top: {style: 'thin', color: {argb: '979797'}},
                left: {style: 'thin', color: {argb: '979797'}},
                bottom: {style: 'thin', color: {argb: '979797'}},
                right: {style: 'thin', color: {argb: '979797'}}
            }
            if (urlColumn > 0) dataRow.getCell(urlColumn).font = this.hyperlinkFont

            if (attachmentColumn > 0 && dataRow.getCell(attachmentColumn).value && dataRow.getCell(attachmentColumn).value.hyperlink)
                dataRow.getCell(attachmentColumn).font = this.hyperlinkFont

            dataRow.alignment = {
                wrapText: true,
                vertical: 'top'
            }
        }
        this._autosizeColumnCells(sheet.columns)
    }

    /**
     * Adjust the column width based on the content
     * @param columns{Array<any>}
     */
    _autosizeColumnCells(columns) {
        columns.forEach((column) => {
            const dataMax = []
            column.eachCell({includeEmpty: false}, (cell) => {
                let cellValue
                if (cell.value && cell.value.richText) cellValue = cell.value.richText.map(richPart => richPart.text).join('')
                else cellValue = cell.value?.toString()
                const isValueMultiline = cellValue && cellValue.indexOf('\n') !== -1
                if (isValueMultiline) {
                    dataMax.push(Math.max(...cellValue.split('\n').map(valueLine => valueLine.length)))
                } else {
                    dataMax.push(cellValue?.length || 0)
                }

            })
            const max = Math.max(...dataMax)
            column.width = max < 18 ? max + 5 : (max > 230 ? 246 : max + 16)
        })
    }

    /**
     * Add link and text for eval users
     * @param sheet{Worksheet} sheet to update
     **/
    _createEvaluationData(sheet) {
        sheet.addRow([])
        sheet.addRow([process.env.REACT_APP_EVAL_TEXT_1])
        sheet.lastRow.font = {name: 'SF Pro Text Regular', size: 14, bold: true}
        sheet.mergeCells(`A${sheet.lastRow.number}:O${sheet.lastRow.number}`)

        sheet.addRow([])
        sheet.lastRow.getCell(1).value = {
            text: process.env.REACT_APP_EVAL_TEXT_2,
            hyperlink: process.env.REACT_APP_EVAL_LIMIT_DOCS_LINK
        }
        sheet.lastRow.font = {...this.hyperlinkFont, size: 14, bold: true, color: {argb: '4b45ff'}}
        sheet.mergeCells(`A${sheet.lastRow.number}:H${sheet.lastRow.number}`)
    }
}

export default MSExcelExport