import React from 'react'
import {Image, Text, View} from '@react-pdf/renderer'
import {PDFExportPageChecklistItemProperty} from '../../../../types/PDFExportTypes'
import ChecklistElement from './ChecklistElement'
import PDFExportPageTitle from '../basic/PDFExportPageTitle'

function PDFExportChecklistItem(props: PDFExportPageChecklistItemProperty) {
    const {checklist, style} = props
    const allItems = checklist.allItems.map((completeItem, index) => {
        return <ChecklistElement key={index} style={style} checklistElement={completeItem}
                                 completed={completeItem.state === 'complete'}/>
    })

    let partLoadedStyle = {...style.partLoaded, width: checklist.percentageComplete + '%'}
    let partNotLoadedStyle = {...style.partNotLoaded, width: checklist.percentageIncomplete + '%'}

    // to add the border radius when we need to show only one bar
    if (checklist.percentageComplete === 100 || checklist.percentageComplete === 0) {
        partLoadedStyle = {...partLoadedStyle, ...style.rightRadius}
        partNotLoadedStyle = {...partNotLoadedStyle, ...style.leftRadius}
    }

    return <View>
        <View style={{...style.cardDetailItemRow, marginTop: 20}}>
            <View style={style.descriptionIcon}>
                <Image src='/icons/pdf-export/CheckBoxOutlinedIcon.png'/>
            </View>
            <View style={style.cardDetailItemValue}>
                <PDFExportPageTitle style={style} styleTitle={style.checklistSectionTitle} title={checklist.name}/>
            </View>
        </View>
        <View style={[style.bannerItemRow, style.marginHorizontalInPage]}>
            <Text style={style.completionPercentage}>{checklist.percentageComplete}%</Text>
            <View style={{...style.bannerItemRow, width: '100%'}}>
                <View style={partLoadedStyle}/>
                <View style={partNotLoadedStyle}/>
            </View>
        </View>
        {allItems}
    </View>
}

export default PDFExportChecklistItem