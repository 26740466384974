import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles( {
    itemBox: {
        borderRadius: '3px',
        backgroundColor: '#F4F5F7',
        padding: '12px 12px 17px 12px',
        margin: '12px',
        minWidth: '270px',
    },
    listName: {
        color: '#172B4E',
        fontFamily: "SF Pro Text Bold",
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: '16px',
        width: '150px',
        overflowWrap: 'break-word'
    },
    cardsLength: {
        color: '#6B778C',
        fontFamily: "SF Pro Text Regular",
        fontSize: '14px',
        // fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '16px',
        marginLeft: '10px'
    },
    selectItemLabel: {
        marginLeft: '-6px',
        '& > *': {
            color: '#172B4D',
            fontFamily: "SF Pro Text Regular",
            fontSize: '14px',
            letterSpacing: 0,
            lineHeight: '20px',
            marginLeft: '-6px',
        }
    },
    selectListContainer: {
        height: '100%',
        paddingBottom: '22px',
        position: 'relative'
    },
    exportCheck: {
        position: 'absolute',
        bottom: '-10px'
    }
  }
)


export { useStyles }
