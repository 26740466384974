import ContentFilter from '../utility/ContentFilter'
import html2canvas from 'html2canvas'
import FileSaver from 'filesaver-js'
import {findBoardArchivedListsCards, getArchivedCards} from '../Persistence'
import {
    collectCardsToPNGExportForEvaluation,
    getCardsToExportForEvaluation,
    isNewEvaluationLicensed
} from "../utility/Utils";

/**
 * Exports the selected lists in Trello to an Image file.
 */
class ImageExport {

    constructor() {
        this.contentFilter = new ContentFilter()
    }

    /**
     * Retrieve the selected lists and archived card lists (if the param includeArchivedCards is true)
     * @param {*} trelloContext The trello context
     * @param {Array<String>} listIds id lists selected
     * @param {boolean} includeArchivedCards if archived cards list are included or not
     * @param {*} licenseDetails object to get current memberId and trello app key
     * @return a list of lists selected with cards {id,name,cards}
     */
    async getSelectedLists(trelloContext, listIds, includeArchivedCards, licenseDetails) {
        const totalList = []

        if (includeArchivedCards) {
            const archivedCards = await getArchivedCards(licenseDetails)
            if (archivedCards && archivedCards.length > 0) {
                const archivedList = {
                    id: new Date().valueOf().toString(),
                    name: 'Archived Cards',
                    cards: archivedCards
                }
                totalList.push(archivedList)
            }

            const archivedCardLists = await findBoardArchivedListsCards(licenseDetails)
            if (archivedCardLists && archivedCardLists.length > 0) {
                totalList.push(...archivedCardLists)
            }
        }

        return trelloContext.lists('all').then((response) => {
            // 1. Filter the lists to return ONLY the ones specified
            const selectedLists = this.contentFilter.filterLists(listIds, response)

            if(isNewEvaluationLicensed(licenseDetails)) {
                const maxCardsToExport = parseInt(process.env.REACT_APP_EVAL_EXPORT_PNG_CARDS || '5')
                const maxNonArchivedCardsToExport = parseInt(process.env.REACT_APP_EVAL_PNG_MAX_NON_ARCHIVED_CARDS || '3')
                const maxArchivedCardsToExport = parseInt(process.env.REACT_APP_EVAL_PNG_MAX_ARCHIVED_CARDS || '2')

                const nonArchivedCards = selectedLists.map(selectedList => selectedList.cards).flat()
                const archivedCards = totalList.map(selectedList => selectedList.cards).flat()
                const evaluationCards = getCardsToExportForEvaluation(nonArchivedCards, archivedCards, maxCardsToExport, maxNonArchivedCardsToExport, maxArchivedCardsToExport)
                return collectCardsToPNGExportForEvaluation(evaluationCards, [...totalList, ...selectedLists])
            }
            totalList.unshift(...selectedLists)
            return totalList
        }).catch(error => {
            console.error('Any selected list was retrieved', error)
            return []
        })
    }

    /**
     * Export the cards and all their field values (including custom) from the lists identified by the given list names.
     * @param {*} boardName The name of the board that we are exporting data for.
     */
    export(boardName) {
        html2canvas(document.getElementById('ui'), {
            scrollX: 0,
            scrollY: -window.scrollY
        }).then((canvas) => {
            const currentDate = (new Date()).toISOString().substring(0, 10).replace('-', '').replace('-', '')
            const fileName = boardName + ' ' + currentDate + '.png'
            canvas.toBlob((blob) => {
                FileSaver.saveAs(blob, fileName)
            })
        }).catch((error) => {
            console.error('Error exporting the board to an image', error)
        })
    }

}

export default ImageExport