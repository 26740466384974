import SectionMessage, {SectionMessageAction} from '@atlaskit/section-message'
import React, {useCallback, useEffect, useState} from 'react'
import {Box, Collapse} from '@material-ui/core'
import {useStyles} from './NewCreationDateCardFieldSectionMessageStyles'
import {
    hasUserDismissCardDateCreationMessage,
    storeUserDismissCardDateCreationMessage,
} from '../../modules/Persistence'

/**
 * Render a message explaining that we have a new feature
 */
export const NewCreationDateCardFieldSectionMessage = (props: { licenseDetails: any }) => {

    const classes = useStyles()

    const [dismissMessage, setDismissMessage] = useState<boolean>(true)
    const [collapsed, setCollapsed] = useState(true)


    const getDismissMessageForUser = useCallback (async () => {
        setDismissMessage(await hasUserDismissCardDateCreationMessage(props.licenseDetails))
    }, [props.licenseDetails])

    useEffect(() => {
        getDismissMessageForUser()
    }, [dismissMessage, getDismissMessageForUser])


    const handleDismissNewFeatureNotification = () => {
        setCollapsed(false)
        setTimeout(() => {
            setDismissMessage(true)
            storeUserDismissCardDateCreationMessage(props.licenseDetails)
        }, 1000)
    }

    if (dismissMessage) return null

    return <Collapse in={collapsed}>
        <Box className={classes.sectionMessageContainer}>
            <SectionMessage
                title="New!"
                actions={<SectionMessageAction onClick={handleDismissNewFeatureNotification}>OK</SectionMessageAction>}
            >
                <p>
                    You can now export your Trello cards' created dates with Board Export!
                </p>
            </SectionMessage>
        </Box>
    </Collapse>
}