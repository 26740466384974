import SectionMessage, {SectionMessageAction} from '@atlaskit/section-message'
import React, {useCallback, useEffect, useState} from 'react'
import {Box, Collapse} from '@material-ui/core'
import {useStyles} from './NewFeatureSectionMessagerStyles'
import {hasUserDismissMessageNewFeature, storeUserDismissMessageNewFeature} from '../../modules/Persistence'

/**
 * Render a message explaining that we have a new feature
 */
export const NewFeatureSectionMessage = (props: { licenseDetails: any }) => {

    const classes = useStyles()

    const [dismissMessage, setDismissMessage] = useState<boolean>(true)
    const [collapsed, setCollapsed] = useState(true)


    const getDismissMessageForUser = useCallback (async () => {
        setDismissMessage(await hasUserDismissMessageNewFeature(props.licenseDetails))
    }, [props.licenseDetails])

    useEffect(() => {
        getDismissMessageForUser()
    }, [dismissMessage, getDismissMessageForUser])


    const handleDismissNewFeatureNotification = () => {
        setCollapsed(false)
        setTimeout(() => {
            setDismissMessage(true)
            storeUserDismissMessageNewFeature(props.licenseDetails)
        }, 1000)
    }

    if (dismissMessage) return null

    return <Collapse in={collapsed}>
        <Box className={classes.sectionMessageContainer}>
            <SectionMessage
                title="New feature"
                actions={<SectionMessageAction onClick={handleDismissNewFeatureNotification}>OK</SectionMessageAction>}
            >
                <p>
                    We have added a new feature that allows you to configure how Custom Fields and Checklists are
                    formatted in the Excel file export. Please click Board Export's "Customisation" menu on the left
                    side of this screen to set up your own preferences.
                </p>
            </SectionMessage>
        </Box>
    </Collapse>
}