import React from 'react'
import {PDFExportCardDetailsBannerProperty} from '../../../../types/PDFExportTypes'
import {Image, Text, View} from '@react-pdf/renderer'
import {findSplitAndReplaceUrls} from '../PDFExportUtils'

/**
 * Verify that the data element exists, and if it does return it
 */
function createBannerItem(title: string, data: any, style: any) {
    if (!data) {
        return
    }
    const wrappedText = findSplitAndReplaceUrls(data, 55)
    return <View style={style.bannerItemRow} key={title.toLowerCase()}>
        <View style={style.cardDetailItemRow}>
            <View style={style.cardDetailItemLabel}>
                <Text>{title}</Text>
            </View>
            <View style={style.cardDetailItemValue}>
                <Text>{wrappedText}</Text>
            </View>
        </View>
    </View>
}


/**
 * Verify that the data element exists, and if it does return it
 */
function createLabelItems(labels: any, style: any) {
    if (!labels || labels.length === 0 || labels.richText.length === 0) {
        return
    }

    const labelsItems = labels.richText.map((label: any, index: number) => {
        const labelName = label.text.slice(0, -1)

        return <View style={{...style.labelItemRect, backgroundColor: '#' + label.font.color.argb}} key={`${labelName}-${index}`}>
            <Text style={style.labelItemText}>{labelName}</Text>
        </View>
    })

    return <View style={[style.bannerItemRow, style.cardDetailItemRow]} key={'labels'}>
        <Text style={style.cardDetailItemLabel}>LABELS:</Text>
        <View style={style.cardDetailItemValue}>
            <View style={style.labelDetailItemRow}>
                {labelsItems}
            </View>
        </View>
    </View>
}

function createDueDateCardDetails(dueDate: any, dueComplete: boolean, style: any) {
    if (!dueDate) {
        return
    }
    const elements = []

    const image = dueComplete ? <Image src='/icons/pdf-export/CheckBoxIcon.png'/> :
        <Image src='/icons/pdf-export/CheckBoxOutlineBlankIcon.png'/>
    elements.push(
        <View key='dueDateCheckBoxIcon' style={style.dueDateCheckBoxIcon}>
            {image}
        </View>
    )

    if (dueComplete) {
        elements.push(
            <View key='dueDateText'>
                <Text>{dueDate.richText[0].text}</Text>
            </View>
        )
        elements.push(
            <View style={{...style.dueDateRect, backgroundColor: '#61bd4f'}} key='dueDateRect'>
                <Text style={style.labelItemText}>Complete</Text>
            </View>
        )
    } else {
        if (dueDate.richText[1].text === 'Overdue') {
            // remove the comma at the end of the text
            const text = dueDate.richText[0].text.slice(0, -2)
            elements.push(
                <View key='dueDateText'>
                    <Text>{text}</Text>
                </View>
            )
            elements.push(
                <View style={{...style.dueDateRect, backgroundColor: '#F45E59'}} key='dueDateRect'>
                    <Text style={style.labelItemText}>Overdue</Text>
                </View>
            )
        } else {
            elements.push(
                <View key='dueDateText'>
                    <Text>{dueDate.richText[0].text}</Text>
                </View>
            )
        }
    }

    return <View style={style.bannerItemRow} key={'dueDate'}>
        <View style={style.bannerItemCol}>
            <Text style={style.bannerItemCell}>DUE DATE:</Text>
        </View>
        <View style={style.bannerItemCol}>
            <View style={style.bannerItemRow}>
                {elements}
            </View>
        </View>
    </View>
}

function creationDate(creationDate: string, style: any) {
    return <View style={style.bannerItemRow} key='creationDate'>
        <View style={style.bannerItemCol}>
            <Text style={style.bannerItemCell}>CARD CREATED DATE:</Text>
        </View>
        <View style={style.bannerItemCol}>
            <View style={style.bannerItemRow}>
                <View key='dueDateText'>
                    <Text>{creationDate}</Text>
                </View>
            </View>
        </View>
    </View>
}

function PDFExportCardDetailsBanner(props: PDFExportCardDetailsBannerProperty) {
    const card = props.card

    const elements = []
    elements.push(createBannerItem('BOARD:', card.boardName, props.style))
    elements.push(createBannerItem('LIST:', card.listName, props.style))

    if (props.selectedFields.includes('labels')) {
        elements.push(createLabelItems(card.labels, props.style))
    }

    if (props.selectedFields.includes('members')) {
        const members = card.members.toString().replaceAll(',', '  / ')
        elements.push(createBannerItem('MEMBERS:', members, props.style))
    }

    if (props.selectedFields.includes('creationDate')) {
        elements.push(creationDate(card.creationDate, props.style))
    }

    if (props.selectedFields.includes('dueDate')) {
        elements.push(createDueDateCardDetails(card.due, card.dueComplete, props.style))
    }


    return <View style={props.style.marginHorizontalInPage}>
        {elements}
    </View>
}

export default PDFExportCardDetailsBanner