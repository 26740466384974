import {Link, Text, View} from '@react-pdf/renderer'
import ReactMarkdown from 'react-markdown'
import React from 'react'
import {PDFRenderMarkdownCOntentProperties} from '../../../../types/PDFExportTypes'
import {ReactMarkdownProps} from 'react-markdown/src/ast-to-react'

const PDFRenderMarkdownContent = (props: PDFRenderMarkdownCOntentProperties) => {

    const components = {
        p: (element: ReactMarkdownProps) => <Text style={props.style.p}>{element.children}</Text>,
        strong: (element: ReactMarkdownProps) => <Text style={props.style.strong}>{element.children}</Text>,
        a: (element: any) => <Link style={props.style.link} src={element.href}>{element.children}</Link>,
        hr: () => <View style={props.style.hr}></View>,
        ol: (element: ReactMarkdownProps) => <View style={props.style.list}>{element.children}</View>,
        ul: (element: any) => {
            const children = element.children.map((child: any) => {
                if(child.props) child = {...child, props: {...child.props, depth: element.depth}}
                return child
            }).slice(1, element.depth === 0 ? element.children.length : -1)
            return <View style={props.style.list}>{children}</View>
        },
        li: (element: any) => {
            let children = element['children']
            if (children && children.length > 0 && children.at(-1) === '\n') children = children.slice(0, -1)
            if (children && children.length > 3 && children.at(0) === '\n') children = children.slice(1, children.length)

            const depthMargin = '    '.repeat(element.depth)
            if (element.ordered) {
                return <View
                    style={props.style.orderedListItem}>{depthMargin}{element.index + 1}- &nbsp;{children}</View>
            }
            return <View style={props.style.listItem}>{depthMargin}• &nbsp;{children}</View>
        },
        code: (element: any) => {
            const isMultilineMalformed = (element.children.length === 1 && element.children[0] === '' && element.node.data && element.node.data.meta)
            if (element.inline || isMultilineMalformed) {
                return <Text style={props.style.inlineCodeBlock}>
                    {element.inline ? element.children : `${element.className.slice(element.className.indexOf('-') + 1)} ${element.node.data.meta}`}
                </Text>
            }
            return <Text>{element.children.map((child: string) => {
                return child.endsWith('```') || child.endsWith('```\n') ? child.slice(0, -4) : child
            })}</Text>
        },
        blockquote: (element: ReactMarkdownProps) => <Text style={props.style.blockquote}>{element.children}</Text>,
        em: (element: ReactMarkdownProps) => <Text style={props.style.em}>{element.children}</Text>,
        h1: (element: ReactMarkdownProps) => <Text style={props.style.h1}>{element.children}</Text>,
        h2: (element: ReactMarkdownProps) => <Text style={props.style.h2}>{element.children}</Text>,
        h3: (element: ReactMarkdownProps) => <Text style={props.style.h3}>{element.children}</Text>,
        h4: (element: ReactMarkdownProps) => <Text style={props.style.h4}>{element.children}</Text>,
        h5: (element: ReactMarkdownProps) => <Text style={props.style.h5}>{element.children}</Text>,
        h6: (element: ReactMarkdownProps) => <Text style={props.style.h6}>{element.children}</Text>,
        img: (element: any) => <Link style={props.style.link} src={element.src}>{element.alt || element.src}</Link>
}


    const quotedCodeBlockRegex = /(`{3} ?[\w]*\n?[\S\s]+?\n?`{3})/gm
    // eslint-disable-next-line
    const blankSpaceCodeBlockRegex = /^((?:(?:^[ ]{4})[^- ].*(\R|\n|$))+)/gm
    const hrRegex = /(---)/gm
    const quoteBlockRegex = /(^> ?.*)(\n)/gm
    const imageRegex = /(!\[[^\]]*\]\(.*?\s*("(?:.*[^"])")?\s*\))/gm
    const composedRegex = new RegExp([quotedCodeBlockRegex, blankSpaceCodeBlockRegex, hrRegex, quoteBlockRegex, imageRegex].map(regex => regex.source).join('|'), 'gm')

    const paragraphsSplit = props.text
        .split(composedRegex)
        .filter((paragraph: string) => paragraph)

    return <View>
        {paragraphsSplit.map((paragraph: string, index: number) => {
            const isCodeBlock = (paragraph.startsWith('```') && paragraph.endsWith('```')) || paragraph.startsWith('    ')
            const isHr = paragraph === '---'
            const isQuoteBlock = paragraph.startsWith('>')
            const isImage = paragraph.startsWith('![')
            if (isCodeBlock || isHr || isQuoteBlock || isImage) {
                return <View style={isCodeBlock ? props.style.codeBlock : null} key={index}>
                    <ReactMarkdown components={components}>
                        {paragraph}
                    </ReactMarkdown>
                </View>
            }
            return <Text key={index}>
                <ReactMarkdown components={components}>
                    {paragraph}
                </ReactMarkdown>
            </Text>
        })
        }
    </View>
}

export default PDFRenderMarkdownContent