import React, { Component } from 'react'
import TrelloListPreview from './TrelloListPreview'

/**
 * Renders a 'preview' of a Trello Board. The preview is styled in the same way as a regular board and contains only the
 * lists that are in the data provided.
 */
class TrelloBoardPreview extends Component {

    componentDidUpdate() {
        /* NOTE: This happend after it has been inserted into the dom but before display, so may not work as expected. */
        if (this.props.isPreview && this.props.lists) {
            // 1. Save Image
            const imageExport = this.props.imageExport
            imageExport.export(this.props.boardName)
            // 2. Hide Preview
            this.props.onImageSaved()
        }
    }

    render() {
        if (this.props.isPreview && this.props.lists) {
            return this.renderPreview()
        } else {
            return this.renderNoPreview()
        }
    }

    renderPreview() {
        const lists = this.props.lists

        const listComponents = lists.map((list) =>
            <TrelloListPreview key={list.id} list={list} />
        )

        const evaluationWarningText = this.props.isRecentEvalLicensed && <div className="evaluationMessagePNG">
            <h1>{process.env.REACT_APP_EVAL_TEXT_1}</h1>
            <h1>{process.env.REACT_APP_EVAL_TEXT_5}</h1>
        </div>
        return (
            <div id="ui" className="ui">
                <nav id="nav-title" className="navbar board" style={{
                    paddingBottom: '20px',
                    color: '#000000'
                }}>{this.props.boardName}</nav>
                <div id="list-container" className="lists">
                    {listComponents}
                    {evaluationWarningText}
                </div>
            </div>
        )
    }

    renderNoPreview() {
        return (
            <div id="ui" className="ui" style={{
                display: 'none'
            }}></div>
        )
    }
}

export default TrelloBoardPreview