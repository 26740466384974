import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles( {
    sectionMessageContainer: {
        marginTop: '36px'
    },
})


export { useStyles }
