import React, { useEffect, useState } from 'react'
import { useStyles } from './CustomisationStyles'
import { Grid, Typography } from '@material-ui/core'
import Toggle from '@atlaskit/toggle'
import { CustomisationValuesEnum } from './CustomisationUtils'
import { CustomisationProperties } from '../../types/CustomisationTypes'
import { TrackActionEvent } from 'trello-shared-resources/dist'

const generateTrackInfo = (trelloContext: any, customisationType: 'customfields' | 'checklists', isFieldChecked: boolean) => {
    const trelloContextInfo = trelloContext.getContext()
    TrackActionEvent('Export Config', trelloContextInfo, {
        board_id: trelloContextInfo.board,
        setting: customisationType,
        action: isFieldChecked ? 'disabled' : 'enabled'
    })
}

const Customisation = (props: CustomisationProperties) => {
    const classes = useStyles()
    const {licenseDetails} = props
    const trelloContext = licenseDetails.trelloIframeContext

    const [isCustomFieldsChecked, setIsCustomFieldsChecked] = useState(false)
    const [isChecklistsChecked, setIsChecklistsChecked] = useState(false)

    useEffect(() => {
        const fetchMemberData = async () => {
            setIsCustomFieldsChecked(await trelloContext.get('member', 'private', CustomisationValuesEnum.CUSTOM_FIELDS_KEY, false))
            setIsChecklistsChecked(await trelloContext.get('member', 'private', CustomisationValuesEnum.CHECKLISTS_KEY, false))
        }
        fetchMemberData()
    }, [trelloContext])

    const toggleChangeEvent = (checkedValue: boolean, customisationValuesEnum: string, customisationType: 'customfields' | 'checklists') => {
        trelloContext.set('member', 'private', customisationValuesEnum, !checkedValue)
        if (customisationType === 'customfields') {
            setIsCustomFieldsChecked(!checkedValue)
        } else {
            setIsChecklistsChecked(!checkedValue)
        }
        generateTrackInfo(trelloContext, customisationType, checkedValue)
    }

    return (
        <Grid item xs={8} md={10} xl={9} className={classes.contentContainer}>
            <Typography className={classes.appTitle}>Excel Customisation</Typography>

            <Typography className={classes.text1}>Custom Fields</Typography>
            <Typography className={classes.text2}>
                Enable this option, if you want Custom Fields to be separated into individual cells in the final export.
            </Typography>
            <div className={classes.toggleDiv}>
                <Toggle
                    size="large"
                    onChange={() => {
                        toggleChangeEvent(isCustomFieldsChecked, CustomisationValuesEnum.CUSTOM_FIELDS_KEY, 'customfields')
                    }}
                    isChecked={isCustomFieldsChecked}
                />
            </div>

            <Typography className={classes.text1}>Checklists</Typography>
            <Typography className={classes.text2}>
                Enable this option, if you want Checklists to be separated into individual cells in the final export.
            </Typography>
            <div className={classes.toggleDiv}>
                <Toggle
                    size="large"
                    onChange={() => {
                        toggleChangeEvent(isChecklistsChecked, CustomisationValuesEnum.CHECKLISTS_KEY, 'checklists')
                    }}
                    isChecked={isChecklistsChecked}
                />
            </div>

        </Grid>
    )
}

export default Customisation