import React, {useEffect, useState} from 'react'
import {Result} from 'antd'
import {SearchOutlined} from '@ant-design/icons';
import ImageExport from '../../modules/export/ImageExport'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import {loadTrelloData} from '../../modules/Persistence'
import {MessageType} from '../../modules/error/FetchDataError'
import {Grid, Typography} from '@material-ui/core'
import {useStyles} from './BoardExportContentStyles'
import TrelloBoardPreview from '../preview/TrelloBoardPreview'
import ListSelector from '../listSelector/ListSelector'
import {NewFeatureSectionMessage} from '../newFeatureSectionMessage/NewFeatureSectionMessage'
import {NewCreationDateCardFieldSectionMessage} from '../newCreationDateCardFieldSectionMessage/NewCreationDateCardFieldSectionMessage'
import {EvaluationLicenseSectionMessage} from '../evaluationLicenseSectionMessage/EvaluationLicenseSectionMessage'
import {isNewEvaluationLicensed} from '../../modules/utility/Utils'

const BoardExportContent = (props) => {

    const classes = useStyles()

    const {licenseDetails} = props

    const [isPreview, setIsPreview] = useState(false)
    const [trelloData, setTrelloData] = useState(null)
    const [selected, setSelected] = useState([])
    const [messageToShow, setMessageToShow] = useState(null)
    const [includeArchivedCards, setIncludeArchivedCards] = useState(false)
    const [selectedListsToExportImg, setSelectedListsToExportImg] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            // Load the Trello Data and set the state when done
            const startingState = await loadTrelloData(licenseDetails)

            if (startingState.trelloData) setTrelloData(startingState.trelloData)
            if (startingState.messageToShow) setMessageToShow(startingState.messageToShow)
        }
        fetchData()
    }, [licenseDetails])

    /**
     * Helper function to show a message to the user. It can be an info message (e.g. no cards in the board) or
     * an error message due to unexpected behaviour (e.g. network error)
     *
     * @param messageToShow
     */
    const renderMessage = (messageToShow) => {
        if (messageToShow.messageType === MessageType.info) {
            return <Grid item xs={8} md={10} xl={9} className={classes.contentContainer}>
                <Result
                    icon={<SearchOutlined/>}
                    title={messageToShow.title}
                    subTitle={messageToShow.subTitle}/>
            </Grid>
        }
        return <Grid item xs={8} md={10} xl={9} className={classes.contentContainer}>
            <Result
                status={messageToShow.messageType}
                title={messageToShow.title}
                subTitle={<div dangerouslySetInnerHTML={{__html: messageToShow.subTitle}}/>}/>
        </Grid>
    }

    const areAllListsSelected = (lists) => selected.filter((selectedItem) => selectedItem.selected).length === lists.length


    /**
     * Setup the selected state so that initially, all the lists are present but with 'false' as their checked value.
     */
    const initialiseSelectedState = (lists) => {
        if (Object.keys(selected).length === 0 && lists.length !== 0) {
            const result = lists.map((list) => ({
                'id': list.id,
                'listName': list.name,
                'selected': false
            }))
            setSelected(result)
        }
    }

    /**
     * Updates the state when a list item is selected. This ensures that we always keep track of which lists the user wishes
     * to export.
     * @param {*} id The unique identifier of the list.
     * @param {*} selected Whether is has been selected of de-selected.
     */
    const handleListItemSelected = (id, selectedOrNot) => {
        const updatedSelectionState = selected.map((selectedItem) => {
            if (selectedItem.id === id) {
                selectedItem['selected'] = selectedOrNot
            }
            return selectedItem
        })
        setSelected(updatedSelectionState)

        const trelloContextInfo = licenseDetails.trelloIframeContext.getContext()
        TrackActionEvent('List Action', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            action: selectedOrNot ? 'select_one' : 'deselect_one'
        })
    }

    /**
     * What to do when the user checks the 'Select All' button. This function updates the state for all of the list options.
     * @param {*} event The event send from the selectAll checkbox.
     */
    const handleSelectAllListItems = (event) => {
        const updatedSelectionState = selected.map((selectedItem) => {
            selectedItem['selected'] = event.target.checked
            return selectedItem
        })
        setSelected(updatedSelectionState)

        const trelloContextInfo = licenseDetails.trelloIframeContext.getContext()
        TrackActionEvent('List Action', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            action: event.target.checked ? 'select_all' : 'deselect_all'
        })
    }

    const handleIncludeArchivedCards = () => {
        setIncludeArchivedCards(!includeArchivedCards)
    }

    if (messageToShow) return renderMessage(messageToShow)

    if (trelloData) {
        const lists = trelloData.lists
        const board = trelloData.board

        initialiseSelectedState(lists)

        return (
            <Grid item xs={8} md={10} xl={9} className={classes.contentContainer}>
                <Typography className={classes.appTitle}>
                    Board Export
                </Typography>
                {!isNewEvaluationLicensed(licenseDetails) &&
                <>
                    <NewFeatureSectionMessage licenseDetails={licenseDetails}/>
                    <NewCreationDateCardFieldSectionMessage licenseDetails={licenseDetails}/>
                </>
                }
                {licenseDetails.isOnlyEvalLicensed() && <EvaluationLicenseSectionMessage {...props}/>}
                <ListSelector lists={lists}
                              selected={selected}
                              selectAll={areAllListsSelected(lists)}
                              includeArchivedCards={includeArchivedCards}
                              handleIncludeArchivedCards={handleIncludeArchivedCards}
                              handleListItemSelected={handleListItemSelected}
                              handleSelectAllListItems={handleSelectAllListItems}
                              licenseDetails={props.licenseDetails}
                              trelloData={trelloData}
                              setSelectedListsToExportImg={setSelectedListsToExportImg}
                              setIsPreview={setIsPreview}/>
                <TrelloBoardPreview boardName={board.name}
                                    isPreview={isPreview}
                                    lists={selectedListsToExportImg}
                                    imageExport={new ImageExport()}
                                    isRecentEvalLicensed={isNewEvaluationLicensed(licenseDetails)}
                                    onImageSaved={() => {
                                        setIsPreview(false)
                                    }}/>
            </Grid>
        )
    } else {
        return (<Grid item xs={8} md={10} xl={9} className={classes.contentContainer}>Loading Trello Data...</Grid>)
    }
}

export default BoardExportContent